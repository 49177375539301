import React from "react";

import { Outlet } from "react-router-dom";
import Header from "../../views/home/shared/header";
import SideMenu from "../../views/home/shared/sideMenu";
import { useTheme } from "contexts/theme/ThemeContext";

function HomeLayout() {
  const {
    theme: {
      homeLayout: { main },
    },
  } = useTheme();

  return (
    <div className={"text-darkGray h-100"}>
      <Header />
      <div className="main-container m-15p">
        <div
          id="side-menu-wrapper"
          className="calc-h-100 rounded-4 position-relative mx-7P side-menu-wrapper collapsed"
        >
          <SideMenu />
          <div
            className="backdrop"
            onClick={() => {
              document.getElementById("side-menu-wrapper")?.classList.toggle("collapsed");
            }}
          />
        </div>
        <main className={`calc-h-100 overflow-auto z-0 ${main.bgColorClass} ${main.roundedClass}`}>
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default HomeLayout;

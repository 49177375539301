import { GetAllPublishersPaymentsResponse } from "./models/publishersPaymentsTypes";

export const publishersPaymentsService = {
  getAllPublishersPayments: async () => {
    const response: GetAllPublishersPaymentsResponse = {
      code: 200,
      description: "Success",
      hasError: false,
      data: {
        totalCount: 3,
        result: [
          {
            id: "1",
            arabicName: "محمد",
            englishName: "Mohammed",
            orderNumber: "2024-12",
            orderId: 1,
            paymentDate: new Date().toISOString(),
            paymentAmount: 100,
            status: "NotPaid",
            paymentReceiptId: "1287127",
          },
          {
            id: "2",
            arabicName: "علي",
            englishName: "Ali",
            orderNumber: "2024-13",
            orderId: 2,
            paymentDate: new Date().toISOString(),
            paymentAmount: 200,
            status: "Paid",
            paymentReceiptId: "1287127",
          },
          {
            id: "3",
            arabicName: "عبدالله",
            englishName: "Abdullah",
            orderNumber: "2024-14",
            orderId: 3,
            paymentDate: new Date().toISOString(),
            paymentAmount: 300,
            status: "Pending",
            paymentReceiptId: "1287127",
          },
        ],
      },
    };
    return response;
  },
  exportToExcel: async () => {
    return null;
  },
};

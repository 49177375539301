import IconButton from "components/blocks/IconButton";
import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PublisherResult } from "services/publishersService/models/publishersTypes";
import ChangePublisherStatusModal from "./ChangePublisherStatusModal";

const PublishersGridView = () => {
  const { t } = useTranslation("Publishers");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { isAr } = useCookieContext();

  const { loading, gridData, totalCount, exportToExcel } = useGridData<"publishersService", PublisherResult>({
    service: "publishersService",
    endpoint: "getAllPublishers",
    exportExcelEndpoint: "exportToExcel",
    exportFileName: "publishers",
    localizationNameSpace: "Publishers",
  });

  const [modalProps, setModalProps] = useState({
    isOpen: false,
    type: "block",
    id: "",
    name: "",
    loading: false,
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "publishersInfo",
        path: "/home/publishersManagement/publishers",
        menuItemId: "publishers",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "id",
        displayName: t("publisherName"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        render: (row: PublisherResult) => {
          return (
            <button
              className="btn btn-link"
              onClick={() => navigate(`/home/publishersManagement/publishers/view?publisherId=${row.id}`)}
            >
              {isAr ? row.arabicName : row.englishName}
            </button>
          );
        },
      },
      {
        field: "fundingOrdersCount",
        displayName: t("fundingOrdersCount"),
      },
      {
        field: "joinDate",
        displayName: t("joinDate"),
        render: (row: PublisherResult) => {
          return toSystemThemeDateFormat(row.joinDate);
        },
      },
      {
        field: "IsActive",
        displayName: t("Common:status"),
        showOnMobile: true,
        render(row: PublisherResult) {
          const statusClassName = row.isActive
            ? "bg-primary bg-opacity-10 text-primary"
            : "bg-danger bg-opacity-10 text-danger";
          return (
            <span className={`badge rounded-4 ${statusClassName} py-2`}>
              {t(row.isActive ? "Common:active" : "Common:blocked")}
            </span>
          );
        },
      },
      {
        field: "id",
        displayName: t("Common:actions"),
        render: (row: PublisherResult) => {
          const innerIconColor = row.isActive ? "danger" : "dark";

          return (
            <IconButton
              title={row.isActive ? t("Common:block") : t("Common:unblock")}
              icon="icon-block"
              fitToIconWidth
              innerIconColor={innerIconColor}
              size="sm"
              onClick={() => {
                onChangePublisherStatus(row.isActive, isAr ? row.arabicName : row.englishName, row.id);
              }}
            />
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const onChangePublisherStatus = (isActive: boolean, name: string, id: string) => {
    setModalProps({
      isOpen: true,
      type: isActive ? "block" : "unblock",
      name: name,
      id: id,
      loading: false,
    });
  };

  const onModalConfirm = async () => {
    // TODO: Implement block/unblock publisher
    setModalProps({
      isOpen: false,
      type: modalProps.type,
      name: "",
      id: "",
      loading: false,
    });
  };

  const onModalClose = () => {
    setModalProps({
      isOpen: false,
      type: modalProps.type,
      name: "",
      id: "",
      loading: false,
    });
  };

  const viewHeader: GridViewHeaderProps = {
    title: t("publishers"),
    singularName: t("publisher"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: gridData ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
      <ChangePublisherStatusModal {...modalProps} onClose={onModalClose} onModalConfirm={onModalConfirm} />
    </>
  );
};

export default PublishersGridView;

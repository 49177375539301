import { GetAllFormTemplatesResponse } from "./models/formTemplatesTypes";

export const FormTemplatesService = {
  getAllFormTemplates: async () => {
    const response: GetAllFormTemplatesResponse = {
      code: 200,
      description: "Success",
      hasError: false,
      data: {
        totalCount: 3,
        result: [
          {
            id: "1",
            order: 1,
            templateName: "اسم النموذج",
            publicationType: "نوع النشر العلمي",
            publicationCategory: "تصنيف النشر العلمي",
            createdOn: new Date().toISOString(),
            status: "Active",
            lastModifiedOn: new Date().toISOString(),
            lastModifiedBy: "عادل محمد",
          },
          {
            id: "2",
            order: 2,
            templateName: "اسم النموذج",
            publicationType: "نوع النشر العلمي",
            publicationCategory: "تصنيف النشر العلمي",
            createdOn: new Date().toISOString(),
            status: "Active",
            lastModifiedOn: new Date().toISOString(),
            lastModifiedBy: "نضال نايف",
          },
          {
            id: "3",
            order: 3,
            templateName: "اسم النموذج",
            publicationType: "نوع النشر العلمي",
            publicationCategory: "تصنيف النشر العلمي",
            createdOn: new Date().toISOString(),
            status: "Inactive",
            lastModifiedOn: new Date().toISOString(),
            lastModifiedBy: "عبدالله محمد",
          },
        ],
      },
    };
    return response;
  },
  exportToExcel: async () => {
    return null;
  },
};

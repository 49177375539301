import { CookieContextType } from "./models/CookieContextType";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { languageChange } from "helpers";

const CookieContext = createContext<CookieContextType | undefined>(undefined);

export const CookieProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Manage multiple cookie values as state
  const [userSession, setUserSession] = useState<string | undefined>();
  const [localization, setLocalization] = useState<string | undefined>();
  const [isArabic, setIsArabic] = useState(true);
  const [isRightToLeft, setIsRightToLeft] = useState(true);
  const [isHijri, setIsHijri] = useState(false);

  useEffect(() => {
    const checkCookieChange = () => {
      const newUserSession = Cookies.get("Session");
      const newLocalization = Cookies.get("localization");
      const newIsHijri = Cookies.get("isHijri") === "true";

      if (newUserSession !== userSession) {
        setUserSession(newUserSession);
      }

      if (newLocalization !== localization) {
        const { CurrentLanguage, isRTL } = JSON.parse(newLocalization || "{}");
        setIsArabic(CurrentLanguage === "ar");
        setIsRightToLeft(isRTL);
        setLocalization(newLocalization);
      }

      if (newIsHijri !== isHijri) {
        setIsHijri(newIsHijri);
      }
    };

    // Set an interval to check for cookie changes every 1000ms (1 second)
    const intervalId = setInterval(checkCookieChange, 100);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [userSession, localization, isHijri]);

  // Function to set a cookie and update state
  const setCookie = (name: string, value: string, options?: Cookies.CookieAttributes) => {
    Cookies.set(name, value, options);
    if (name === "Session") setUserSession(value);
    if (name === "localization") {
      const { CurrentLanguage, isRTL } = JSON.parse(value);
      setIsArabic(CurrentLanguage === "ar");
      setIsRightToLeft(isRTL);
      setLocalization(value);
      languageChange(CurrentLanguage);
    }
    if (name === "isHijri") setIsHijri(value === "true");
  };

  // Function to remove a cookie and update state
  const removeCookie = (name: string) => {
    Cookies.remove(name);
    if (name === "Session") setUserSession(undefined);
    if (name === "localization") setLocalization(undefined);
    if (name === "isHijri") setIsHijri(false);
  };

  return (
    <CookieContext.Provider
      value={{ userSession, localization, setCookie, removeCookie, isAr: isArabic, isRTL: isRightToLeft, isHijri }}
    >
      {children}
    </CookieContext.Provider>
  );
};

// Custom hook to use the CookieContext
export const useCookieContext = (): CookieContextType => {
  const context = useContext(CookieContext);
  if (context === undefined) {
    throw new Error("useCookieContext must be used within a CookieProvider");
  }
  return context;
};

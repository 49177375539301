import AttachmentCard from "components/blocks/AttachmentCard";
import FilePreview from "components/blocks/FileInput/FilePreview";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import RichTextEditor from "components/RichTextEditor";
import { useCookieContext } from "contexts";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CycleByIDResponse } from "services/cyclesService/models/cyclesTypes";
import { FileService } from "services/fileService";

const AboutCycleTab = ({ cycle }: { cycle: CycleByIDResponse }) => {
  const { t } = useTranslation("Cycles");
  const { isAr } = useCookieContext();

  const cycleDetails = cycle.cycleDetails?.find((x: any) => x.locale === (isAr ? "Ar" : "En"));

  const generatePdf = (content: string, fileName?: string) => async () => {
    if (!content) return;
    await FileService.htmlToPdf(content, `${fileName ?? "template"}.pdf`);
  };

  const financingGridSchema = useMemo<GridSchema[]>(
    () => [
      {
        displayName: t("rewardType"),
        field: "-",
        showOnMobile: true,
        render: (item: CycleByIDResponse["financingCalculationItems"][0]) =>
          item.financingType.financingTypesDetail.displayName,
      },
      {
        displayName: t("calculationMethod"),
        field: "-",
        render: (item: CycleByIDResponse["financingCalculationItems"][0]) =>
          item.financingType?.calculationWay?.calculationWayDetail?.displayName,
      },
      {
        displayName: t("amount"),
        field: "-",
        showOnMobile: true,
        render: (item: CycleByIDResponse["financingCalculationItems"][0]) => `${item.value} ${t("Common:riyal")}`,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column gap-4">
      <div>
        <h6 className="fw-bold mb-4">{t("cycleDescription")}</h6>
        <p className="px-2">{cycleDetails?.description}</p>
      </div>
      <div className="d-flex flex-column gap-2">
        <h6 className="fw-bold mb-0">{t("publishingConditions")}</h6>
        <RichTextEditor initialData={cycleDetails?.ruleAndConditionTemplate ?? ""} isReadOnly onChange={() => {}} />
        <FilePreview
          wrapperClassName="align-self-start"
          title={cycleDetails?.ruleAndConditionName}
          callbackValue="1"
          onShow={generatePdf(cycleDetails?.ruleAndConditionTemplate ?? "", cycleDetails?.ruleAndConditionName)}
          hideDeleteButton
        />
      </div>
      <div className="d-flex flex-column gap-2">
        <h6 className="fw-bold mb-0">{t("commitment")}</h6>
        <RichTextEditor initialData={cycleDetails?.commitmentTemplate ?? ""} isReadOnly onChange={() => {}} />
        <FilePreview
          wrapperClassName="align-self-start"
          title={cycleDetails?.commitmentName}
          callbackValue="2"
          onShow={generatePdf(cycleDetails?.commitmentTemplate ?? "", cycleDetails?.commitmentName)}
          hideDeleteButton
        />
      </div>

      <div className="divider" />

      <div>
        <h6 className="fw-bold mb-3">{t("Common:attachments")}</h6>
        <div className="d-flex gap-4">
          {cycle.files?.map((file: any) => (
            <AttachmentCard
              key={file.id}
              attachment={{
                extension: file.extension,
                fileName: file.name,
                id: file.id,
                size: file.size,
              }}
            />
          ))}
        </div>
      </div>

      <div className="divider" />

      <div>
        <h6 className="fw-bold mb-3">{t("rewardCalculationTerms")}</h6>
        <Grid data={cycle.financingCalculationItems} gridSchema={financingGridSchema} />
      </div>
    </div>
  );
};

export default AboutCycleTab;

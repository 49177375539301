import IconButton from "components/blocks/IconButton";
import { PageHeaderProps, PageWithTabsProps } from ".";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useScreenSize from "hooks/useScreenSize";

const HeadersSection = (props: PageWithTabsProps & { activeTabId: string }) => {
  const { t } = useTranslation("Common");
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const onBackClicked = () => {
    navigate(-1);
  };

  return (
    <div className={props.tabs ? "border-bottom border-1" : ""}>
      <div className="d-flex gap-2">
        {props.titleImgSrc && (
          <img
            src={props.titleImgSrc}
            alt="logo"
            height={125}
            className="rounded-4 ms-3"
            style={{ objectFit: "cover", borderRadius: "1rem", marginBottom: "24px" }}
          />
        )}
        <div className=" d-flex flex-column justify-content-between gap-3 flex-1">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex gap-2 align-items-center">
              {!props.titleImgSrc && (
                <IconButton
                  icon="icon-full-arrow-right"
                  size="lg"
                  fitToIconWidth
                  onClick={onBackClicked}
                  innerIconColor="secondary"
                  iconPosition="start"
                />
              )}

              <div>
                <div className="fs-5 fw-bold">{props.title}</div>
                <div className="fs-6 text-gray">{props.subTitle}</div>
                {/* Actions */}
                {props.actions && (
                  <div className="d-sm-none d-flex flex-wrap gap-2 mt-3">{props.actions(props.activeTabId)}</div>
                )}
              </div>
            </div>

            {/* Actions */}
            {props.actions && (
              <div className="d-none d-sm-flex flex-wrap gap-2">{props.actions(props.activeTabId)}</div>
            )}
          </div>

          {/* Page Headers */}
          {screenSize !== "mobile" && props.pageHeaders && (
            <div
              className={`d-none d-sm-flex flex-wrap ${
                props.tabs && !props.titleImgSrc ? "px-4 pb-4 pt-2" : props.titleImgSrc ? "pb-4" : ""
              }`}
            >
              {props.pageHeaders
                .filter((ele) => (ele?.renderCondition ? ele?.renderCondition(props.activeTabId) : true))
                .map((header: PageHeaderProps, index: number) => (
                  <div key={index} className="d-flex align-items-center">
                    {index !== 0 && <div className="header-circle text-gray mx-3 d-none d-sm-block" />}
                    <div>
                      {<div className="fs-14px text-gray">{header.title}:</div>}
                      <div className="fs-14px fw-bold">{header.value ? header.value : t("Common:noData")}</div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      {screenSize === "mobile" && props.pageHeaders && (
        <div className="row px-4 pb-4 pt-2">
          {props.pageHeaders
            .filter((ele) => (ele?.renderCondition ? ele?.renderCondition(props.activeTabId) : true))
            .map((header: PageHeaderProps, index: number) => (
              <div key={index} className="col-6 d-flex align-items-center">
                <div className="header-circle text-gray mx-3" />
                <div>
                  {<div className="fs-14px text-gray">{header.title}:</div>}
                  <div className="fs-14px fw-bold">{header.value ? header.value : t("Common:noData")}</div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default HeadersSection;

import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import "./PublicationsDetails.scss";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useTranslation } from "react-i18next";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useCookieContext } from "contexts";
import PublicationBookCard from "./PublicationBookCard";

const FileCard = () => {
  return (
    <div className="file-card flex-1 d-flex gap-2 p-4 bg-white rounded-4 align-items-start shadow">
      <span className="icon-epub fs-48px" />
      <div className="flex-1">
        <h6 className="mb-1 fw-bold">العربية</h6>
        <p className="m-0 text-gray">اسم المترجم</p>
        <p className="mt-3 mb-0">
          لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات
          دولار ماجنا أليكيوا
        </p>
      </div>
    </div>
  );
};

const PublicationsDetails = () => {
  const { userSession, isAr } = useCookieContext();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { t, i18n } = useTranslation("ScientificPublication");

  const { id } = useParams();

  // TODO: get data

  return (
    <div className=" bg-white d-flex flex-column gap-4 position-relative text-dark">
      {/* TODO: add loading */}
      <LoadingOverlay visible={false} className="h-100" />

      <div className="shadow py-1  px-6rem ">
        <NavBar tapClassName="text-dark" variant="dark" />
      </div>

      <div className="publications-details-container d-flex gap-4 pb-5">
        {/* Right Side (Image, Info, etc.) */}
        <div className="d-flex flex-column gap-4 align-items-center">
          <img
            className="rounded-1 book-image"
            src={require("../../../../assets/image/temp-book-thumb.jpeg")}
            alt="publication"
          />
          <div className="d-flex w-100 justify-content-between align-items-center gap-2">
            <div className="text-center">
              <p className="m-0">{t("numberOfPages")}</p>
              <p className="m-0 fw-bold">15</p>
            </div>
            <div className="h-50 border-start" />
            <div className="text-center">
              <p className="m-0">{t("scientificField")}</p>
              <p className="m-0 fw-bold">علوم الشريعة</p>
            </div>
          </div>

          <img className="barcode mt-2" src={require("./temp-barcode.svg").default} alt="barcode" />
        </div>
        {/* End of Right Side */}

        {/* Left Side (Info, etc.) */}
        <div className="border-start" />
        <div className="flex-1 d-flex flex-column gap-2">
          <div className="d-flex gap-2">
            <p className="m-0 fs-12px">{t("scientificPublications")}</p>
            <span className="icon-arrow-left fs-20px" />
            <p className="m-0 fs-12px">اسم النشر العلمي</p>
          </div>

          {/* Header */}
          <div>
            <h5 className="mt-4 fw-bold">اسم النشر العلمي</h5>
            <p className="mt-3">
              لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري
              ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد أكسير سيتاشن يللأمكو لابورأس نيسي يت
              أليكيوب أكس أيا كوممودو كونسيكيوات . ديواس
            </p>
          </div>
          {/* End of Header */}

          {/* Striped Table Info */}
          <div className="striped d-flex flex-column position-relative ">
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("scientificPublicationNumber")}</span>
              <span className="flex-1">1234-5678</span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publisherName")}</span>
              <span className="flex-1">اسم الناشر</span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publicationTypeCategory")}</span>
              <span className="flex-1">نشر كتاب - كتاب</span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publicationLanguage")}</span>
              <span className="flex-1">عربي</span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publicationDate")}</span>
              <span className="flex-1">{toSystemThemeDateFormat(new Date())}</span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publicationOwner")}</span>
              <span className="flex-1">جهة النشر</span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publicationOwnerType")}</span>
              <span className="flex-1">نوع جهة النشر</span>
            </div>
          </div>
          {/* End of Striped Table Info */}

          <div className="divider my-4" />

          {/* Files */}
          <div>
            <h5 className="mb-4">{t("scientificPublications")}</h5>
            <div className="d-flex flex-wrap gap-3">
              <FileCard />
              <FileCard />
              <FileCard />
            </div>
          </div>
          {/* End of Files */}

          <div className="divider mb-2 mt-4" />

          {/* Libraries */}
          <div>
            <h5 className="mb-4">{t("availableIn")}</h5>
            <div className="d-flex flex-wrap gap-3">
              {Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className="p-4 bg-white rounded-4 align-items-center shadow">
                  <h6 className="mb-1 fw-bold text-center">مكتبة جرير</h6>
                  <p className="m-0 text-gray text-center">الموقع</p>
                </div>
              ))}
            </div>
          </div>
          {/* End of Libraries */}

          <div className="divider mb-3 mt-4" />

          {/* Similar Publications */}
          <div className="d-flex flex-column gap-3">
            <h5 className="mb-4">{t("similarPublications")}</h5>
            <div className="d-flex flex-wrap gap-3">
              {Array.from({ length: 6 }).map((_, index) => (
                <PublicationBookCard key={index} />
              ))}
            </div>
          </div>
          {/* End of Similar Publications */}

          {/* End of Left Side */}
        </div>
      </div>
    </div>
  );
};

export default PublicationsDetails;

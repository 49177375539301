import { createContext, useContext, useState, type ReactNode } from "react";

type ThemeContextType = {
  theme: {
    homeLayout: {
      main: {
        bgColorClass: string;
        roundedClass: string;
      };
    };
  };
  setTheme: (theme: ThemeContextType["theme"]) => void;
  resetTheme: () => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

const initialTheme: ThemeContextType["theme"] = {
  homeLayout: {
    main: {
      bgColorClass: "bg-white",
      roundedClass: "rounded-4",
    },
  },
};

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState(initialTheme);

  const resetTheme = () => {
    setTheme(initialTheme);
  };

  return <ThemeContext.Provider value={{ theme, setTheme, resetTheme }}>{children}</ThemeContext.Provider>;
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return context;
};

import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScientificPublicationResult } from "services/scientificPublicationsService/models/scientificPublicationsTypes";

const ScientificPublicationGridView = () => {
  const { t } = useTranslation("ScientificPublication");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const { loading, gridData, totalCount, exportToExcel } = useGridData<
    "scientificPublicationsService",
    ScientificPublicationResult
  >({
    service: "scientificPublicationsService",
    endpoint: "getAllScientificPublications",
    exportExcelEndpoint: "exportToExcel",
    exportFileName: "scientificPublications",
    localizationNameSpace: "ScientificPublication",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "scientificPublication",
        path: "/home/scientificPublication",
        menuItemId: "scientificPublication",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "id",
        displayName: "",
        showOnMobile: true,
        render: (row: ScientificPublicationResult) => {
          // TODO: when this becomes a server image path (row.image), remove the require() function in the img tag
          return (
            <img
              src={require("../../../assets/image/temp-book-thumb.jpeg")}
              alt="publication thumbnail"
              style={{ width: 55 }}
            />
          );
        },
      },
      {
        field: "publicationName",
        displayName: t("publicationName"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        render: (row: ScientificPublicationResult) => {
          return (
            <button className="btn btn-link" onClick={() => navigate(`/home/scientificPublication/${row.id}`)}>
              {row.publicationName}
            </button>
          );
        },
      },
      {
        field: "publicationCategory",
        displayName: t("publicationCategory"),
      },
      {
        field: "publisherName",
        displayName: t("publisherName"),
      },
      {
        field: "publishingDate",
        displayName: t("publishingDate"),
        render: (row: ScientificPublicationResult) => {
          return toSystemThemeDateFormat(row.publishingDate);
        },
      },
      {
        field: "createdBy",
        displayName: t("createdBy"),
        render: (row: ScientificPublicationResult) => {
          return `${row.createdBy} - ${toSystemThemeDateFormat(row.createdOn)}`;
        },
      },
      {
        field: "status",
        displayName: t("Common:status"),
        render: (row: ScientificPublicationResult) => {
          const statusClassName = row.status === "Published" ? "bg-success text-success " : "bg-primary text-danger";
          return (
            <span className={`badge bg-opacity-10 rounded-4 ${statusClassName} py-2`}>
              {t(row.status.toLocaleLowerCase())}
            </span>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("scientificPublications"),
    singularName: t("scientificPublication"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
    addPageRoute: "/home/scientificPublication/form",
  };

  return (
    <GridView
      loading={loading}
      gridProps={{
        data: gridData ?? [],
        gridSchema,
      }}
      viewHeaderProps={viewHeader}
    />
  );
};

export default ScientificPublicationGridView;

import { FormCheck } from "react-bootstrap";

type CheckboxProps = {
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  containerClassName?: string;
};

const Checkbox = ({ label, onChange, checked, containerClassName }: CheckboxProps) => {
  return (
    <div className={`d-flex gap-2 mb-3 themed-checkbox ${containerClassName}`}>
      <FormCheck id={label} type="checkbox" className="" onChange={onChange} checked={checked} />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

export default Checkbox;

import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import DatePicker from "components/blocks/DatePicker";
import Dropdown from "components/blocks/Dropdown";
import FormInput from "components/blocks/FormInput";
import { useTranslation } from "react-i18next";

const SPFormMainInfo: React.FC = () => {
  const { t } = useTranslation("ScientificPublication");

  return (
    <Accordion negatePadding>
      <AccordionItem initiallyOpen title={t("mainInfo")} elementId="main-info">
        <div className="d-flex flex-column gap-3 mb-3">
          <FormInput label={t("publicationNameAr")} placeholder={t("publicationNameAr")} />
          <FormInput label={t("publicationDescriptionAr")} placeholder={t("publicationDescriptionAr")} />
          <FormInput label={t("publicationNameEn")} placeholder={t("publicationNameEn")} />
          <FormInput label={t("publicationDescriptionEn")} placeholder={t("publicationDescriptionEn")} />
        </div>

        <div className="row">
          <Dropdown
            data={[]}
            label={t("publicationField")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationField")}
          />
          <Dropdown
            data={[]}
            label={t("publicationType")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationType")}
          />
          <Dropdown
            data={[]}
            label={t("publicationCategory")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationCategory")}
          />
          <Dropdown
            data={[]}
            label={t("publicationLanguage")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationLanguage")}
          />

          <FormInput
            label={t("publisherName")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            placeholder={t("publisherName")}
          />
          <DatePicker
            label={t("publicationDate")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            placeholder={t("publicationDate")}
          />
          <Dropdown
            data={[]}
            label={t("publicationOwner")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationOwner")}
          />
          <Dropdown
            data={[]}
            label={t("publicationOwnerType")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationOwnerType")}
          />
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default SPFormMainInfo;

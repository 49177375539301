import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FormViewComponent from "components/formBuilder/FormViewComponent";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import { useCookieContext } from "contexts";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const OrderInfoTab: React.FC = () => {
  const { t } = useTranslation("Orders");
  const { isAr } = useCookieContext();

  // TODO: move all data and accordion items to separate components/files
  const ApplicantInfo = ({ orderId }: { orderId: string }) => {
    const applicantData = {
      appliedUserName: "محمد خالد سليمان",
      degree: "استاذ مشارك",
      collage: "كلية الشريعة",
      section: "قسم الفقه",
      specialization: "الفقه الإسلامي",
      employer: "جهة العمل",
      email: "ali@gmail.com",
      phoneNumber: "0599999999",
    };
    const applicantsGridSchema = useMemo<GridSchema[]>(
      () => [
        {
          field: "appliedUserName",
          displayName: t("Cycles:appliedUserName"),
          showOnMobile: true,
        },
        {
          field: "degree",
          displayName: t("Cycles:degree"),
        },
        {
          field: "collage",
          displayName: t("Cycles:collage"),
        },
        {
          field: "section",
          displayName: t("Cycles:section"),
        },
        {
          field: "specialization",
          displayName: t("Cycles:specialization"),
          showOnMobile: true,
        },
        {
          field: "employer",
          displayName: t("Cycles:employer"),
        },
        {
          field: "email",
          displayName: t("Common:email"),
        },
        {
          field: "phoneNumber",
          displayName: t("Common:phoneNumber"),
        },
      ],
      [isAr],
    );
    return <Grid data={[applicantData]} gridSchema={applicantsGridSchema} />;
  };

  return (
    <div>
      <Accordion>
        <AccordionItem title="معلومات مقدم الطلب" initiallyOpen elementId="main-info">
          <ApplicantInfo orderId="1" />

          <div className="mt-3 mb-4">
            <h6 className="fw-bold">{t("suggestedTitle")}</h6>
            <p className="fs-14px">dummy title</p>
          </div>

          <div>
            <h6 className="fw-bold">{t("publicationDetails")}</h6>
            <FormViewComponent readOnly data={{}} formDefinition={{}} onChange={() => {}} />
          </div>

          <div className="pb-4">
            <h6 className="fw-bold">{t("attachmentsFullFile")}</h6>
            <div className="d-flex flex-wrap gap-3 mt-2 ">
              {[1, 2, 3, 4, 5, 6].map((item) => (
                <AttachmentCard
                  key={item}
                  attachment={{
                    id: item,
                    fileName: `test.${item % 2 === 0 ? "pdf" : "doc"}`,
                    size: "5.4MB",
                    extension: item % 2 === 0 ? "pdf" : "doc",
                  }}
                />
              ))}
            </div>
          </div>

          <h6 className="fw-bold mb-3 mt-4">{t("scientificPublicationForm")}</h6>
          <FilePreview
            style={{ width: "fit-content" }}
            title="نموذج الرسائل العلمية - رسائل الدكتوراه"
            onShow={() => {}}
            file={new File([], "test.pdf")}
            hideDeleteButton
            showFileSize={false}
          />
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default OrderInfoTab;

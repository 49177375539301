import { GetAllScientificPublicationsResponse } from "./models/scientificPublicationsTypes";

export const ScientificPublicationsService = {
  getAllScientificPublications: async () => {
    const response: GetAllScientificPublicationsResponse = {
      code: 200,
      description: "ok",
      hasError: false,
      data: {
        totalCount: 2,
        result: [
          {
            id: 1,
            image: "some-image-path.jpeg",
            publicationName: "اسم النشر العلمي",
            publicationCategory: "تصنيف المنشور العلمي",
            publisherName: "اسم الناشر",
            publishingDate: new Date().toISOString(),
            createdOn: new Date().toISOString(),
            createdBy: "علي محمد",
            status: "Published",
          },
          {
            id: 2,
            image: "some-image-path.jpeg",
            publicationName: "اسم النشر العلمي",
            publicationCategory: "تصنيف المنشور العلمي",
            publisherName: "اسم الناشر",
            publishingDate: new Date().toISOString(),
            createdOn: new Date().toISOString(),
            createdBy: "علي محمد",
            status: "NotPublished",
          },
        ],
      },
    };
    return response;
  },
  exportToExcel: async () => {
    return null;
  },
};

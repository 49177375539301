import { BaseResponseType } from "services/shared-models/sharedTypes";
import { GetAllOrdersResponse, OrderByIDResponse } from "./models/ordersTypes";

export const OrdersService = {
  getAllOrders: async () => {
    const response: GetAllOrdersResponse = {
      code: 200,
      description: "ok",
      hasError: false,
      data: {
        totalCount: 1,
        result: [
          {
            id: "123",
            orderNumber: "sample data",
            cycleName: "sample data",
            applicantName: "sample data",
            publicationType: "sample data",
            publicationCategory: "sample data",
            applicationDate: new Date().toISOString(),
            researchCenter: "sample data",
            orderPhase: "sample data",
            responsibleEntity: "sample data",
            orderStatus: "InProgress",
          },
        ],
      },
    };
    return response;
  },
  getOrderByID: async (orderId: string) => {
    const response: BaseResponseType<OrderByIDResponse> = {
      code: 200,
      description: "ok",
      hasError: false,
      // TODO
      data: {},
    };

    return response;
  },
  exportToExcel: async () => {
    return null;
  },
};

import IconButton from "components/blocks/IconButton";
import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import RewardConfirmationModal from "components/RewardsModals/RewardConfirmationModal";
import RewardSettingsModal from "components/RewardsModals/RewardSettingsModal";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RewardStatus } from "services/examinersRewardsService/models/examinersRewardsTypes";
import { JudgeRewardResult } from "services/judgesRewardsService/models/judgesRewardsTypes";

type RewardConfirmationModalProps = {
  rewardId: number;
  isOpen: boolean;
  status: keyof typeof RewardStatus;
  amount: number;
  name: string;
};

const JudgesRewardsGridView = () => {
  const { t } = useTranslation("Judges");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { isAr } = useCookieContext();

  const [rewardConfirmationModalState, setRewardConfirmationModalState] = useState<RewardConfirmationModalProps>({
    rewardId: 0,
    isOpen: false,
    status: "Pending",
    amount: 0,
    name: "",
  });

  const { loading, gridData, totalCount, exportToExcel } = useGridData<"judgesRewardsService", JudgeRewardResult>({
    service: "judgesRewardsService",
    endpoint: "getAllJudgesRewards",
    exportExcelEndpoint: "exportToExcel",
    exportFileName: "judgesRewards",
    localizationNameSpace: "Judges",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "judgesRewards",
        path: "/home/judgesManagement/judgeRewards",
        menuItemId: "judgesRewards",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "id",
        displayName: t("judgeName"),
        showOnMobile: true,
        sortable: true,
        filterable: true,
        render: (row: JudgeRewardResult) => {
          return isAr ? row.arabicName : row.englishName;
        },
      },
      {
        field: "orderNumber",
        displayName: t("orderNumber"),
        render: (row: JudgeRewardResult) => {
          return (
            <button className="btn btn-link" onClick={() => navigate(`/home/orders/${row.orderId}`)}>
              {row.orderNumber}
            </button>
          );
        },
      },
      {
        field: "researchCenter",
        displayName: t("researchCenter"),
      },
      {
        field: "applicantName",
        displayName: t("applicantName"),
      },
      {
        field: "rewardApprovalDate",
        displayName: t("rewardApprovalDate"),
        render: (row: JudgeRewardResult) => {
          return toSystemThemeDateFormat(row.rewardApprovalDate);
        },
      },
      {
        field: "rewardAmount",
        displayName: t("rewardAmount"),
        render: (row: JudgeRewardResult) => {
          return `${row.rewardAmount} ${t("Common:riyal")}`;
        },
      },
      {
        field: "status",
        displayName: t("Common:status"),
        showOnMobile: true,
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "select",
          options: [
            { id: "Paid", value: t("paid") },
            { id: "NotPaid", value: t("notpaid") },
            { id: "Reject", value: t("reject") },
            { id: "Pending", value: t("pending") },
          ],
        },
        render(row: JudgeRewardResult) {
          const statusClassName =
            row.status === "NotPaid" || row.status === "Reject"
              ? "bg-danger text-danger"
              : row.status === "Paid"
              ? "bg-success text-success"
              : "bg-primary text-primary";
          return (
            <span className={`badge rounded-4 ${statusClassName} bg-opacity-10 py-2`}>
              {t(row.status.toLowerCase())}
            </span>
          );
        },
      },
      {
        field: "id",
        displayName: t("Common:actions"),
        showOnMobile: true,
        render: (row: JudgeRewardResult) => {
          if (row.status === "Paid") return null;
          return (
            <IconButton
              title={t("Common:edit")}
              icon="icon-edit"
              fitToIconWidth
              innerIconColor="dark"
              size="md"
              onClick={() => onConfirmationModalOpen(row)}
            />
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("judgesRewards"),
    singularName: t("Common:result"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
    actionsComponent: <RewardSettingsModal settingKey="judgeReward" />,
  };

  const onConfirmationModalOpen = (row: JudgeRewardResult) => {
    setRewardConfirmationModalState({
      isOpen: true,
      amount: row.rewardAmount,
      rewardId: Number(row.id),
      status: row.status as keyof typeof RewardStatus,
      name: (isAr ? row.arabicName : row.englishName) ?? "",
    });
  };

  const onConfirmationModalClose = (refetch = false) => {
    setRewardConfirmationModalState({
      isOpen: false,
      amount: 0,
      status: "Pending",
      name: "",
      rewardId: 0,
    });
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: gridData ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />

      <RewardConfirmationModal
        type="judge"
        isOpen={rewardConfirmationModalState.isOpen}
        onClose={onConfirmationModalClose}
        data={rewardConfirmationModalState}
      />
    </>
  );
};

export default JudgesRewardsGridView;

import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import DatePicker from "components/blocks/DatePicker";
import Dropdown from "components/blocks/Dropdown";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FileUploadWithPreview from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import Switch from "components/blocks/Switch";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import RewardCalculationItems from "./components/RewardCalculationItems";

// TODO: types
type CreateCycleRequest = any;

type CycleFormProps = {
  cycleId?: string;
};

const CycleForm: React.FC<CycleFormProps> = (props) => {
  // Props
  const { cycleId } = props;

  // Hooks
  const { t } = useTranslation("Cycles");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isRTL, isAr } = useCookieContext();
  const { setBreadcrumbs } = useBreadcrumb();

  // STATES
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [cycleSerialNumber, setCycleSerialNumber] = useState("");

  // EFFECTS
  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allCycles",
        path: "/home/cycles",
        menuItemId: "allCycles",
      },
      {
        localizationKey: cycleId ? "updateCycle" : "addCycle",
        path: `/home/cycles/form${cycleId ? `?id=${cycleId}` : ""}`,
      },
    ]);
  }, []);

  // FORM
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    reset,
    setError,
  } = useForm<CreateCycleRequest>();

  const onSubmit = (data: CreateCycleRequest) => {
    console.log(data);
  };

  return (
    <form className="position-relative" noValidate onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay visible={loading} className="position-fixed" />

      {/*** Form Page Header - Start ***/}
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div className="d-flex gap-2 align-items-center">
          <IconButton
            icon={isRTL ? "icon-full-arrow-right" : "icon-full-arrow-left"}
            innerIconColor="black"
            size="lg"
            className="text-dark"
            onClick={() => navigate(-1)}
          />
          <div className="d-flex gap-3 align-items-start">
            <div>
              <h4 className="text-dark mb-0">{cycleId ? t("updateCycle") : t("addCycle")}</h4>
              {cycleSerialNumber && <span>{cycleSerialNumber}</span>}
            </div>
            <Switch
              className="mt-1"
              checked={watch("status") === "Completed"}
              onChange={(checked) => {
                setValue("status", checked ? "Completed" : "Draft");
              }}
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          <button className="btn btn-primary px-3" type="submit" disabled={submitting}>
            {submitting && <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />}
            {t("Common:add")}
          </button>
          <button className="btn btn-outline-secondary px-3" type="submit" disabled={submitting}>
            {submitting && <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />}
            {t("Common:saveDraft")}
          </button>
        </div>
      </div>
      {/*** Form Page Header - End ***/}

      <div className="divider" />

      {/*** Cycle Main Info - Start ***/}
      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("mainInfo")} elementId={1}>
          <div className="d-flex flex-column gap-2">
            <FormInput label={t("cycleNameAr")} />
            <FormInput label={t("cycleDescriptionAr")} />
            <FormInput label={t("cycleNameEn")} />
            <FormInput label={t("cycleDescriptionEn")} />
          </div>
        </AccordionItem>
      </Accordion>
      {/*** Cycle Main Info - End ***/}

      <div className="divider" />

      {/*** About Cycle - Start ***/}
      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("aboutCycle")} elementId={2}>
          <div className="row">
            <FormInput label={t("year")} wrapperClassName="col-sm-6 col-md-3" />
            <FormInput label={t("cycleNumber")} wrapperClassName="col-sm-6 col-md-3" />
            <DatePicker label={t("startDate")} wrapperClassName="col-sm-6 col-md-3" />
            <DatePicker label={t("closeDate")} wrapperClassName="col-sm-6 col-md-3" />
          </div>
        </AccordionItem>
      </Accordion>
      {/*** About Cycle - End ***/}

      <div className="divider" />

      {/*** Rules/Conditions - Start ***/}
      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("conditions")} elementId={3}>
          <Dropdown data={[]} label={t("publishingConditions")} wrapperClassName="mb-2" />
          <FilePreview
            onShow={() => {}}
            showButtonLabel={t("openAndEdit")}
            callbackValue="preview"
            wrapperClassName="mb-4"
            title="data-value.file"
          />
          <Dropdown data={[]} label={t("commitment")} wrapperClassName="mb-2" />
          <FilePreview
            onShow={() => {}}
            showButtonLabel={t("openAndEdit")}
            callbackValue="preview"
            title="data-value.file"
          />
        </AccordionItem>
      </Accordion>
      {/*** Rules - End ***/}

      <div className="divider" />

      {/*** Others - Start ***/}
      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("others")} elementId={4}>
          <FileUploadWithPreview
            files={[
              { file: new File([""], "file"), fileName: "attachment-1.png", size: "1.3MB", uuid: "1" },
              { file: new File([""], "file"), fileName: "attachment-2.png", size: "2.1MB", uuid: "2" },
            ]}
            setFiles={() => {}}
            onChange={(files) => {}}
            formProps={{}}
          />
          <div className="divider" />
          <FileUploadWithPreview
            label={t("cycleImage")}
            clickHereForLabel={t("toAttachCycleImage")}
            files={[{ file: new File([""], "file"), fileName: "cycle-image.png", size: "640KB", uuid: "1" }]}
            setFiles={() => {}}
            onChange={(files) => {}}
            formProps={{}}
            maxNumberOfFiles={1}
          />
        </AccordionItem>
      </Accordion>
      {/*** Others - End ***/}

      <div className="divider" />

      {/*** Reward calculation rules/conditions - Start ***/}
      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("rewardCalculationConditions")} elementId={5}>
          <RewardCalculationItems onAdd={() => {}} data={[]} />
        </AccordionItem>
      </Accordion>
      {/*** Reward calculation conditions - End ***/}
    </form>
  );
};

export default CycleForm;

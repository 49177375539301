import { GetAllExaminersResponse } from "./models/examinersTypes";

export const ExaminersService = {
  getAllExaminers: async () => {
    const response: GetAllExaminersResponse = {
      code: 200,
      description: "Success",
      hasError: false,
      data: {
        totalCount: 3,
        result: [
          {
            id: "130c3afa-34a0-4d03-0135-08dcd353ecc2",
            arabicName: "مؤسسة النشر العلمي",
            englishName: "Scientific Publishing Foundation",
            completedTasks: 5,
            username: "user1",
            joinDate: new Date().toISOString(),
            isActive: true,
          },
          {
            id: "130c3afa-34a0-4d03-0135-08dcd353ecc2",
            arabicName: "مؤسسة النشر العلمي",
            englishName: "Scientific Publishing Foundation",
            completedTasks: 5,
            username: "user2",
            joinDate: new Date().toISOString(),
            isActive: false,
          },
          {
            id: "130c3afa-34a0-4d03-0135-08dcd353ecc2",
            arabicName: "مؤسسة النشر العلمي",
            englishName: "Scientific Publishing Foundation",
            completedTasks: 5,
            username: "user3",
            joinDate: new Date().toISOString(),
            isActive: true,
          },
        ],
      },
    };
    return response;
  },
  exportToExcel: async () => {
    return null;
  },
};

import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import Checkbox from "components/blocks/Checkbox";
import Dropdown, { DropdownItemType } from "components/blocks/Dropdown";
import FileUploadWithPreview from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SPFormDetails: React.FC = () => {
  const { t } = useTranslation("ScientificPublication");

  const [selectedLibraries, setSelectedLibraries] = useState<DropdownItemType[]>([{ id: "1", value: "Library 1" }]);
  const [libraries, setLibraries] = useState<DropdownItemType[]>([
    { id: "1", value: "Library 1" },
    { id: "2", value: "Library 2" },
    { id: "3", value: "Library 3" },
  ]);

  return (
    <Accordion negatePadding>
      <AccordionItem initiallyOpen title={t("details")} elementId="details">
        <FormInput label={t("searchKeywords")} placeholder={t("searchKeywords")} />
        <div className="d-flex flex-wrap gap-2 mt-2">
          <div className="keyword-badge">
            <span>كلمات البحث</span>
            <span role="button" onClick={() => console.log("on-remove")} className="icon-close fs-5 text-muted" />
          </div>
          <div className="keyword-badge">
            <span>English</span>
            <span role="button" onClick={() => console.log("on-remove")} className="icon-close fs-5 text-muted" />
          </div>
        </div>

        <div className="divider my-4" />

        <FileUploadWithPreview
          label={t("publicationImageFile")}
          files={[
            {
              uuid: "1",
              size: "5.7MB",
              file: new File([], "file.pdf"),
              fileName: "اسم الملف.jpg",
            },
          ]}
          setFiles={() => {}}
          formProps={{}}
          maxNumberOfFiles={1}
          clickHereForLabel={t("toAttachPublicationImage")}
          subTitle={t("maxFileSize5MB")}
          onChange={() => {}}
          maxFileSizeKb={1024 * 5}
        />

        <div className="divider my-4" />

        <div className="row">
          <FormInput
            label={t("numberOfPages")}
            placeholder={t("numberOfPages")}
            wrapperClassName="col-12 col-md-4 col-lg-3 mb-3"
          />
          <FormInput
            label={t("publicationLink")}
            placeholder={t("publicationLink")}
            wrapperClassName="col-12 col-md-4 col-lg-3 mb-3"
          />
          <FormInput
            label={t("ISSNSerialNumber")}
            placeholder={t("ISSNSerialNumber")}
            wrapperClassName="col-12 col-md-4 col-lg-3 mb-3"
          />
        </div>
        <FormInput label={t("metaData")} placeholder={t("metaData")} />

        <div className="divider my-4" />

        <Checkbox label={t("prepareHardCopy")} containerClassName="mb-3" />
        <Dropdown
          data={libraries.filter((lib) => !selectedLibraries.some((selectedLib) => selectedLib.id === lib.id))}
          label={t("libraryName")}
          wrapperClassName="mb-3 col-12 col-md-6 col-lg-4"
          noSelectionPlaceholder={t("libraryName")}
          onChange={(_, item) => setSelectedLibraries((prev) => [...prev, item])}
          value=""
        />
        <div className="d-flex flex-wrap gap-2 mt-2">
          {selectedLibraries.map((selectedLib) => (
            <div className="keyword-badge">
              <span>{selectedLib.value}</span>
              <span
                role="button"
                onClick={() => setSelectedLibraries((pre) => pre.filter((lib) => lib !== selectedLib))}
                className="icon-close fs-5 text-muted"
              />
            </div>
          ))}
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default SPFormDetails;

import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useNavigate } from "react-router-dom";

const PublicationBookCard = () => {
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const navigate = useNavigate();

  return (
    <div
      role="button"
      className="p-3 bg-white rounded-3 border d-flex gap-3 flex-1 flex-column"
      style={{ minWidth: "200px", maxWidth: "240px" }}
      // navigate to publication details
      onClick={() => navigate(`/PublicationsDetails/${1}`)}
    >
      <img className="rounded-1" src={require("../../../../assets/image/temp-book-thumb.jpeg")} alt="publication" />
      <div className="flex-1 justify-content-center align-items-center">
        <h5 className="fw-bold text-center">الإكليل في استنباط الترتيل</h5>
        <p className="text-sm text-gray-500 text-center m-0">الرسائل العلمية - رسائل الدكتوراه</p>
        <p className="text-sm text-gray text-center m-0 mt-2">الناشر: محمد فيصل</p>
        <p className="text-sm text-gray text-center m-0">{toSystemThemeDateFormat(new Date())}</p>
      </div>
    </div>
  );
};

export default PublicationBookCard;

export interface DropdownItemType extends Record<string, any> {
  value: string;
  id: string | number;
}

export type DropdownProps = {
  label?: string;
  data: DropdownItemType[];
  noSelectionPlaceholder?: string;
  wrapperClassName?: string;
  value?: string | number;
  onChange?: (value: string, item: DropdownItemType) => void;
  disabled?: boolean;
  formProps?: any;
  selectClassName?: string;
  changeEffect?: (value?: any) => void;
  error?: string;
  labelClassName?: string;
  minWidth?: number;
};

// TODO: Arrow icon coloring (primary)

const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    label,
    data,
    noSelectionPlaceholder,
    wrapperClassName,
    value,
    onChange,
    disabled,
    selectClassName,
    formProps,
    changeEffect,
    error,
    labelClassName,
    minWidth,
  } = props;

  const onLocalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(e.target.value, data.find((item) => `${item.id}` === e.target.value)!);
    formProps?.onChange?.(e);
    changeEffect?.(e.target.value);
  };

  return (
    <div className={`${wrapperClassName}`} style={{ minWidth: minWidth ?? 150 }}>
      {label && <label className={`mb-1  ${labelClassName}`}>{label}</label>}
      <select
        value={value}
        className={`form-select form-control ${selectClassName} ${error ? "is-invalid" : ""}`}
        aria-label={label}
        disabled={disabled}
        {...formProps}
        onChange={onLocalChange}
      >
        <option value="" className="text-muted" disabled>
          {noSelectionPlaceholder ?? label}
        </option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.value}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default Dropdown;

import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import FileUploadWithPreview from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import { useTranslation } from "react-i18next";

const AdditionalAttachmentsTab: React.FC = () => {
  const { t } = useTranslation("Orders");

  return (
    <div>
      <Accordion>
        <AccordionItem elementId="additional-attachments" initiallyOpen title={t("newAttachment")}>
          <FileUploadWithPreview formProps={{}} files={[]} setFiles={() => {}} />

          <div className="d-flex gap-3">
            <FormInput wrapperClassName="flex-1" label={t("Common:description")} />
            <button className="btn btn-primary align-self-end px-4 form-btn">{t("Common:add")}</button>
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider" />

      <Accordion>
        <AccordionItem initiallyOpen elementId="attachments" title={t("Common:attachments")}>
          <div className="d-flex flex-wrap gap-3">
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <AttachmentCard
                key={item}
                attachment={{
                  id: "1",
                  extension: item % 2 === 0 ? "pdf" : "doc",
                  fileName: "test.pdf",
                  size: "5.4MB",
                }}
              />
            ))}
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AdditionalAttachmentsTab;

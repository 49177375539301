import { useRef } from "react";
import "./Switch.scss";

type SwitchProps = {
  checked: boolean;
  label?: string;
  onChange: (checked: boolean) => void;
  className?: string;
};

const Switch: React.FC<SwitchProps> = ({ checked, label, onChange, className }) => {
  const id = useRef(Math.random().toString(36).substring(7));

  const onLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <div className={`d-flex gap-1 ${className}`}>
      <div className="form-check form-switch">
        <input
          checked={checked}
          className="form-check-input"
          type="checkbox"
          id={id.current}
          onChange={onLocalChange}
        />
      </div>
      {label && (
        <label className="form-check-label user-select-none" htmlFor={id.current}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Switch;

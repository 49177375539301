import useGridStore from "components/GridView/grid.store";
import useFileDownload from "hooks/useFileDownload";
import { useNotification } from "hooks/useNotification";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import { BackendServices } from "services";
import { useCookieContext } from "contexts";

type Props<T extends keyof typeof BackendServices, J> = {
  service: T;
  endpoint: keyof (typeof BackendServices)[T];
  exportExcelEndpoint: keyof (typeof BackendServices)[T];
  exportFileName: string;
  localizationNameSpace: string;
};

const useGridData = <T extends keyof typeof BackendServices, J>({
  service,
  endpoint,
  exportExcelEndpoint,
  exportFileName,
  localizationNameSpace,
}: Props<T, J>) => {
  const { t } = useTranslation(localizationNameSpace);
  const downloadFile = useFileDownload();
  const { showNotification } = useNotification();
  const { isAr } = useCookieContext();
  const ready = useRef(false);

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
    }
  }, [resetGridStore]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchGridData();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchGridData({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [gridData, setGridData] = useState<any[]>([]);

  const fetchGridData = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    if (!ready.current) {
      // Prevent fetching if called from an external source when not ready (fetchGridData is exported)
      return;
    }

    try {
      setLoading(true);
      const response = await (BackendServices[service][endpoint] as any)({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: Object.keys(filters)
          .filter((key) => filters[key])
          .map((key) => ({
            field: key,
            value: filters[key],
            operator: "Contains",
          })),
      });

      setGridData(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = async () => {
    try {
      setLoading(true);
      const response = await (BackendServices[service][exportExcelEndpoint] as any)({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: Object.keys(filters)
          .filter((key) => filters[key])
          .map((key) => ({
            field: key,
            value: filters[key],
            operator: "Contains",
          })),
      });
      await downloadFile(response, exportFileName.endsWith(".xlsx") ? exportFileName : `${exportFileName}.xlsx`);
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    gridData: gridData as J[],
    fetchGridData,
    loading,
    totalCount,
    exportToExcel,
  };
};

export default useGridData;

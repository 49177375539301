import "./LoadingOverlay.scss";

type LoadingOverlayProps = {
  visible?: boolean;
  className?: string;
  position?: "fixed" | "absolute";
};

export default function LoadingOverlay({ visible = true, className, position = "absolute" }: LoadingOverlayProps) {
  if (!visible) return null;

  return (
    <div className={`loading-overlay rounded-3 ${className}`} style={{ position: position }}>
      <div className="spinner-border text-primary">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

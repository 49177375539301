import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseResponseType } from "services/shared-models/sharedTypes";
import { useNotification } from "./useNotification";

type UseQueryOptions = {
  enabled?: boolean;
};

type UseQueryProps<T> = {
  triggers?: any[];
  queryFn: (...args: any[]) => Promise<BaseResponseType<T>>;
  options?: UseQueryOptions;
  onError?: (error: any) => void;
};

export const useQuery = <T>({ queryFn, options, triggers, onError }: UseQueryProps<T>) => {
  const { t } = useTranslation("Common");
  const { showNotification } = useNotification();
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(
      async (params?: any) => {
        setLoading(true);

        try {
          const response = await queryFn(params);
          if (response.hasError) {
            setData(null);
            showNotification({ description: response.description, type: "error" });
            onError && onError(response);
          } else {
            setData(response.data);
          }
        } catch (err) {
          showNotification({ description: t("Common:internalServerError"), type: "error" });
          setData(null);
          onError && onError(err);
        } finally {
          setLoading(false);
        }
      },
      [queryFn, t],
  );

  const { enabled = true } = options || {};

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, JSON.stringify(triggers)]);

  return { data, loading, setData, refetch: fetchData };
};

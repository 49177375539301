import IconButton from "components/blocks/IconButton";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import PageWithTabs from "components/PageWithTabs";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useQuery } from "hooks/useQuery";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { OrdersService } from "services/ordersService";
import { OrderByIDResponse } from "services/ordersService/models/ordersTypes";
import OrderInfoTab from "./tabs/OrderInfoTab";
import LettersTab from "./tabs/LettersTab";
import AdditionalAttachmentsTab from "./tabs/AdditionalAttachmentsTab";
import SubActionsTab from "./tabs/SubActionsTab";
import MinutesOfMeetingTab from "./tabs/MinutesOfMeetingTab";

type TapsIds = "info" | "minutesOfMeeting" | "letters" | "attachments" | "history";
const tabsIds: { [key in TapsIds]: TapsIds } = {
  info: "info",
  minutesOfMeeting: "minutesOfMeeting",
  letters: "letters",
  attachments: "attachments",
  history: "history",
};

const OrderView: React.FC = () => {
  const { orderId } = useParams();
  const { t } = useTranslation("Orders");
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  // TODO: replace dummy data with response from this query
  const { data: orderData, loading } = useQuery<OrderByIDResponse>({
    queryFn: async () => await OrdersService.getOrderByID(orderId ?? ""),
    triggers: [orderId],
    options: { enabled: !!orderId },
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allOrders",
        path: "/home/orders",
        menuItemId: "allOrders",
      },
      {
        localizationKey: "order-number",
        path: `/home/orders/${orderId}`,
        localizationParameters: { orderNumber: "123-2024" },
      },
    ]);
  }, [orderData, orderId]);

  const subTitle = orderData ? `${t("Cycles:cycleNumber")}: ${"12-2024"} • ${t("Cycles:theCycle")}: ${"1-2024"}` : "";

  return (
    <>
      <LoadingOverlay visible={loading} position="fixed" />
      <PageWithTabs
        title={
          <div className="d-flex gap-3 align-items-center">
            {"اسم الدورة"}
            <span role="button" className="text-primary icon-view" />
          </div>
        }
        subTitle={subTitle}
        defaultTabId="info"
        pageHeaders={
          !orderData
            ? []
            : [
                {
                  title: t("orderNumber"),
                  value: "123-2024",
                },
                {
                  title: t("applicationDate"),
                  value: toSystemThemeDateFormat("2024-01-01"),
                },
                {
                  title: t("publicationTypeCategory"),
                  value: "نشر كتاب - كتاب",
                },
                {
                  title: t("publicationLanguage"),
                  value: "عربي",
                },
                {
                  title: t("researchCenter"),
                  value: "مركز بحوث علوم الشريعة",
                },
                {
                  title: t("numberOfPages"),
                  value: "100",
                },
                {
                  title: t("publicationDate"),
                  value: toSystemThemeDateFormat("2024-01-01"),
                },
                {
                  title: t("rewardReceipt"),
                  value: (
                    <button className="btn btn-link" type="button">
                      {t("receipt")}
                    </button>
                  ),
                },
              ]
        }
        actions={(selectedTabId) => {
          return (
            <>
              <IconButton variant="light" icon="icon-share" />
              <IconButton variant="light" icon="icon-edit" />
              <IconButton variant="light" innerIconColor="danger" icon="icon-delete" />
              {selectedTabId !== tabsIds.info && <button className="btn btn-primary px-4">{t("Common:save")}</button>}
            </>
          );
        }}
        tabs={[
          {
            id: tabsIds.info,
            title: t("info"),
            content: <OrderInfoTab />,
          },
          {
            id: tabsIds.minutesOfMeeting,
            title: t("minutesOfMeeting"),
            content: <MinutesOfMeetingTab />,
          },
          {
            id: tabsIds.letters,
            title: t("letters"),
            content: <LettersTab />,
          },
          {
            id: tabsIds.attachments,
            title: t("attachments"),
            content: <AdditionalAttachmentsTab />,
          },
          {
            id: tabsIds.history,
            title: t("history"),
            content: <SubActionsTab />,
          },
        ]}
      />
    </>
  );
};

export default OrderView;

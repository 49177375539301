import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrderResult } from "services/ordersService/models/ordersTypes";

type OrdersGridViewProps = {
  cycleId?: string;
  userId?: string;
  columnsToHide?: string[];
};

const OrdersGridView: React.FC<OrdersGridViewProps> = ({ cycleId, userId, columnsToHide }) => {
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const { loading, gridData, totalCount, exportToExcel } = useGridData<"ordersService", OrderResult>({
    service: "ordersService",
    endpoint: "getAllOrders",
    exportExcelEndpoint: "exportToExcel",
    exportFileName: "orders",
    localizationNameSpace: "Orders",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allOrders",
        path: "/home/orders",
        menuItemId: "allOrders",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "id",
        displayName: t("orderNumber"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        isHidden: columnsToHide?.includes("id"),
        render: (row: OrderResult) => {
          return (
            <button className="btn btn-link" onClick={() => navigate(`/home/orders/${row.id}`)}>
              {row.orderNumber}
            </button>
          );
        },
      },
      {
        field: "cycleName",
        displayName: t("cycleName"),
        isHidden: columnsToHide?.includes("cycleName"),
      },
      {
        field: "applicantName",
        displayName: t("applicantName"),
        isHidden: columnsToHide?.includes("applicantName"),
      },
      {
        field: "publicationType",
        displayName: t("publicationTypeCategory"),
        isHidden: columnsToHide?.includes("publicationType"),
        render(row: OrderResult) {
          return `${row.publicationType} - ${row.publicationCategory}`;
        },
      },
      {
        field: "applicationDate",
        displayName: t("applicationDate"),
        isHidden: columnsToHide?.includes("applicationDate"),
        render: (row: OrderResult) => {
          return toSystemThemeDateFormat(row.applicationDate);
        },
      },
      {
        field: "researchCenter",
        displayName: t("researchCenter"),
        isHidden: columnsToHide?.includes("researchCenter"),
      },
      {
        field: "orderPhase",
        displayName: t("orderPhase"),
        isHidden: columnsToHide?.includes("orderPhase"),
      },
      {
        field: "responsibleEntity",
        displayName: t("responsibleEntity"),
        isHidden: columnsToHide?.includes("responsibleEntity"),
      },
      {
        field: "orderStatus",
        displayName: t("orderStatus"),
        showOnMobile: true,
        isHidden: columnsToHide?.includes("orderStatus"),
        render: (row: OrderResult) => {
          const statusClassName =
            row.orderStatus === "Completed" ? "bg-success text-success " : "bg-primary text-primary";
          return <span className={`badge bg-opacity-10  rounded-4 ${statusClassName} py-2`}>{t(row.orderStatus)}</span>;
        },
      },
    ],
    [t, toSystemThemeDateFormat, columnsToHide],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("orders"),
    singularName: t("order"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
  };

  return (
    <GridView
      loading={loading}
      gridProps={{
        data: gridData ?? [],
        gridSchema,
      }}
      viewHeaderProps={viewHeader}
    />
  );
};

export default OrdersGridView;

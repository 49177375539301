import IconButton from "components/blocks/IconButton";
import PageWithTabs from "components/PageWithTabs";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SPVMainInfo from "./tabs/SPVMainInfo";
import SPVStatistics from "./tabs/SPVStatistics";

type PublicationViewProps = {};

const PublicationView: React.FC<PublicationViewProps> = (props) => {
  // HOOKS
  const { id } = useParams();
  const { t } = useTranslation("ScientificPublication");
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  // EFFECTS
  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "scientificPublication",
        path: "/home/scientificPublication",
        menuItemId: "scientificPublication",
      },
      {
        // TODO: value from fetch result
        value: "مؤسسة النشر العلمي",
        path: `/home/scientificPublication/${id}`,
      },
    ]);
  }, [id]);

  return (
    <PageWithTabs
      title={"اسم النشر العلمي"}
      subTitle={t("scientificPublicationSerialNumber", { serialNumber: "15-2024" })}
      titleImgSrc={require("../../../../assets/image/temp-book-thumb.jpeg")}
      defaultTabId="mainInfo"
      pageHeaders={[
        {
          title: t("publisher"),
          value: "مؤسسة النشر العلمي",
        },
        {
          title: t("publicationTypeCategory"),
          value: "نشر كتاب - كتاب",
        },
        {
          title: t("publicationLanguage"),
          value: "عربي",
        },
        {
          title: t("publicationDate"),
          value: toSystemThemeDateFormat(new Date()),
        },
      ]}
      actions={() => (
        <div className="d-flex gap-2">
          <IconButton icon="icon-share" variant="light" />
          <IconButton icon="icon-edit" variant="light" />
          <IconButton icon="icon-delete" variant="light" innerIconColor="danger" />
        </div>
      )}
      tabs={[
        {
          id: "mainInfo",
          title: t("mainInfo"),
          content: <SPVMainInfo />,
        },
        {
          id: "statistics",
          title: t("statistics"),
          content: <SPVStatistics />,
        },
      ]}
    />
  );
};

export default PublicationView;

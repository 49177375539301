import { GetAllExaminersRewardsResponse } from "./models/examinersRewardsTypes";

export const ExaminersRewardsService = {
  getAllExaminersRewards: async () => {
    const response: GetAllExaminersRewardsResponse = {
      code: 200,
      description: "Success",
      hasError: false,
      data: {
        totalCount: 3,
        result: [
          {
            id: "1",
            arabicName: "مؤسسة النشر العلمي",
            englishName: "Scientific Publishing Foundation",
            applicantName: "محمد عبدالله",
            orderNumber: "1",
            orderId: 1,
            researchCenter: "مركز البحوث",
            rewardAmount: 200,
            rewardApprovalDate: new Date().toISOString(),
            status: "Paid",
          },
          {
            id: "2",
            arabicName: "مؤسسة النشر العلمي",
            englishName: "Scientific Publishing Foundation",
            applicantName: "عبدالله محمد",
            orderNumber: "2",
            orderId: 2,
            researchCenter: "مركز البحوث",
            rewardAmount: 100,
            rewardApprovalDate: new Date().toISOString(),
            status: "Pending",
          },
          {
            id: "3",
            arabicName: "مؤسسة النشر العلمي",
            englishName: "Scientific Publishing Foundation",
            applicantName: "عبدالله محمد",
            orderNumber: "3",
            orderId: 3,
            researchCenter: "مركز البحوث",
            rewardAmount: 150,
            rewardApprovalDate: new Date().toISOString(),
            status: "NotPaid",
          },
        ],
      },
    };
    return response;
  },
  exportToExcel: async () => {
    return null;
  },
};

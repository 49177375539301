import Checkbox from "components/blocks/Checkbox";
import Dropdown from "components/blocks/Dropdown";
import FileUploadWithPreview from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import Modal from "components/blocks/Modal";
import Switch from "components/blocks/Switch";
import { useTranslation } from "react-i18next";

type SPFAttachmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  // other data props (add/edit)
};

const SPFAttachmentModal: React.FC<SPFAttachmentModalProps> = (props) => {
  const { t } = useTranslation("ScientificPublication");

  return (
    <Modal
      size="lg"
      isOpen={props.isOpen}
      onClose={props.onClose}
      withHeaderBorder
      bodyMargin="mt-1"
      containerClassName="bg-white"
      headerContent={
        <div className="d-flex align-items-center gap-2">
          <h5>{t("addFile")}</h5>
          <Switch checked={true} onChange={() => {}} />
        </div>
      }
    >
      <FileUploadWithPreview
        maxNumberOfFiles={1}
        files={[
          {
            fileName: "السم الملف.pdf",
            uuid: "123",
            file: new File([], "السم الملف.pdf"),
            size: "10MB",
          },
        ]}
        formProps={{}}
        setFiles={() => {}}
      />

      <FormInput wrapperClassName="mt-4" label={t("Common:description")} />
      <div className="row">
        <FormInput wrapperClassName="col-12 col-md-6 mt-2" label={t("translatorName")} />
        <Dropdown
          data={[
            { id: "1", value: "عربي" },
            { id: "2", value: "انجليزي" },
          ]}
          wrapperClassName="col-12 col-md-6 mt-2"
          label={t("fileLanguage")}
        />
      </div>

      <Checkbox containerClassName="mt-4" label={t("allowFileDownload")} />

      <div className="divider my-4" />

      <div className="d-flex justify-content-center gap-2">
        <button className="btn btn-primary" style={{ width: 150 }}>
          {t("Common:add")}
        </button>
        <button className="btn btn-outline-secondary" style={{ width: 150 }}>
          {t("Common:cancel")}
        </button>
      </div>
    </Modal>
  );
};

export default SPFAttachmentModal;

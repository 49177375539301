import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import Switch from "components/blocks/Switch";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SPFAttachmentModal from "./SPFAttachmentModal";

export const extensionsMapper = {
  pdf: "pdf",
  doc: "doc",
  docx: "doc",
};

type SPFormAttachmentsTableProps = {
  scientificPublicationId?: string;
  viewOnly?: boolean;
};

// TODO: Replace with the actual type and move to the service models
type ScientificPublicationAttachment = {
  id: string;
  isActive: boolean;
  description: string;
  translatorName: string;
  createdOn: string;
  language: string;
  canDownload: boolean;
  file: {
    // other props
    extension: string;
  };
};

const SPFormAttachmentsTable: React.FC<SPFormAttachmentsTableProps> = (props) => {
  const { scientificPublicationId, viewOnly } = props;

  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { t } = useTranslation("ScientificPublication");
  const [fileModalProps, setFileModalProps] = useState({
    isOpen: false,
  });

  const [data, setData] = useState<ScientificPublicationAttachment[]>([
    {
      id: "1",
      isActive: true,
      description: "الوصف 1",
      translatorName: "عبدالله محمد",
      createdOn: "2024-01-01",
      language: "عربي",
      canDownload: true,
      file: {
        extension: "pdf",
      },
    },
    {
      id: "2",
      isActive: false,
      description: "الوصف 2",
      translatorName: "محمد علي",
      createdOn: "2024-01-02",
      language: "انجليزي",
      canDownload: true,
      file: {
        extension: "doc",
      },
    },
  ]);

  const gridSchema: GridSchema[] = [
    {
      field: "isActive",
      displayName: "",
      showOnMobile: true,
      isHidden: !!viewOnly,
      render(row, index) {
        return <Switch checked={row.isActive} onChange={() => console.log("on-change")} />;
      },
    },
    {
      field: "description",
      displayName: t("Common:description"),
      showOnMobile: true,
    },
    {
      field: "translatorName",
      displayName: t("translatorName"),
    },
    {
      field: "createdOn",
      displayName: t("createdOn"),
      render(row) {
        return toSystemThemeDateFormat(row.createdOn);
      },
    },
    {
      field: "language",
      displayName: t("language"),
    },
    {
      field: "canDownload",
      displayName: t("canDownload"),
      render(row) {
        return row.canDownload ? t("allowed") : t("notAllowed");
      },
    },
    {
      field: "isActive",
      displayName: t("Common:status"),
      showOnMobile: true,
      isHidden: !viewOnly,
      render(row, index) {
        const statusClassName = row.isActive
          ? "bg-primary bg-opacity-10 text-primary"
          : "bg-danger bg-opacity-10 text-danger";
        return (
          <span className={`badge rounded-4 ${statusClassName} py-2`}>
            {t(row.isActive ? "published" : "notPublished")}
          </span>
        );
      },
    },
    {
      field: "fileExtension",
      displayName: t("file"),
      showOnMobile: true,
      render(row) {
        return (
          <span
            className={`icon-${extensionsMapper[row.file.extension as keyof typeof extensionsMapper] ?? "pdf"}-big`}
          />
        );
      },
    },
    {
      field: "actions",
      displayName: t("Common:actions"),
      showOnMobile: true,
      isHidden: !!viewOnly,
      render(row) {
        return (
          <div className="d-flex gap-2">
            <span role="button" className="icon-edit fs-5" />
            <span role="button" className="icon-delete text-danger fs-6" />
          </div>
        );
      },
    },
  ];

  const onFileModalClose = () => {
    setFileModalProps({ isOpen: false });
  };

  return viewOnly ? (
    <Grid data={data} gridSchema={gridSchema} />
  ) : (
    <>
      <Accordion negatePadding>
        <AccordionItem
          initiallyOpen
          title={t("Common:attachments")}
          elementId="attachments"
          extraHeaders={
            <button
              onClick={() => setFileModalProps((pre) => ({ ...pre, isOpen: true }))}
              className="btn btn-primary align-self-center px-4 mx-4"
            >
              {t("addFile")}
            </button>
          }
        >
          <Grid data={data} gridSchema={gridSchema} />
        </AccordionItem>
      </Accordion>
      <SPFAttachmentModal isOpen={fileModalProps.isOpen} onClose={onFileModalClose} />
    </>
  );
};

export default SPFormAttachmentsTable;

import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import Dropdown from "components/blocks/Dropdown";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FormInput from "components/blocks/FormInput";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useTranslation } from "react-i18next";

const LettersTab: React.FC = () => {
  const { t } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const gridSchema: GridSchema[] = [
    { field: "letterNumber", displayName: t("letterNumber") },
    { field: "letterName", displayName: t("letterName") },
    { field: "letterDescription", displayName: t("letterDescription") },
    { field: "createdBy", displayName: t("createdBy") },
    {
      field: "createdOn",
      displayName: t("createdOn"),
      render(row) {
        return toSystemThemeDateFormat(row.createdOn);
      },
    },
    {
      field: "letter",
      displayName: t("letter"),
      render(row) {
        return (
          <div className="d-flex gap-2">
            <span className="icon-pdf-big fs-20px" />
            <span className="icon-doc-big fs-20px" />
          </div>
        );
      },
    },
    {
      field: "actions",
      displayName: t("Common:actions"),
      render(row) {
        return <span className="icon-delete text-danger fs-20px" />;
      },
    },
  ];

  const data = [
    {
      letterNumber: "1",
      letterName: "letter 1",
      letterDescription: "description 1",
      createdBy: "user 1",
      createdOn: "2021-01-01",
    },
  ];

  return (
    <div>
      <Accordion>
        <AccordionItem initiallyOpen elementId="newLetter" title={t("newLetter")}>
          <div className="row mb-3">
            <div className="col-12 col-md-3 mt-2">
              <Dropdown
                minWidth={100}
                wrapperClassName="flex-1"
                label={t("letterTemplates")}
                data={[{ id: "1", value: "قوالب الخطابات" }]}
              />
              <FilePreview
                wrapperClassName="d-flex d-md-none mt-3"
                style={{ width: "fit-content" }}
                onShow={() => {}}
                file={new File([], "test.pdf")}
              />
            </div>
            <div className="col-12 col-md-5 mt-2">
              <FormInput label={t("Common:description")} />
            </div>
            <div className="col-12 col-md-4 mt-2 d-flex gap-2 ">
              <Dropdown
                minWidth={80}
                label={t("templateLanguage")}
                wrapperClassName="flex-1"
                data={[
                  { id: "arabic", value: "عربي" },
                  { id: "english", value: "انجليزي" },
                ]}
              />
              <button className="btn btn-primary px-4 py-2 align-self-start form-btn" style={{ marginTop: 25 }}>
                {t("release")}
              </button>
            </div>
          </div>
          {/* this is a duplicate of the file preview in the mobile view */}
          <FilePreview
            wrapperClassName="d-none d-md-flex"
            style={{ width: "fit-content" }}
            onShow={() => {}}
            file={new File([], "test.pdf")}
          />
        </AccordionItem>
      </Accordion>

      <div className="divider" />

      <Accordion>
        <AccordionItem initiallyOpen elementId="letters" title={t("letters")}>
          <Grid data={data} gridSchema={gridSchema} />
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default LettersTab;

import { BaseResponseType } from "services/shared-models/sharedTypes";
import { CycleByIDResponse, GetAllCyclesResponse } from "./models/cyclesTypes";

export const CyclesService = {
  getAllCycles: async () => {
    const dummyData: GetAllCyclesResponse = {
      code: 200,
      description: "ok",
      hasError: false,
      data: {
        totalCount: 1,
        result: [
          {
            id: "123",
            name: "sample data",
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
            status: "Draft",
            applicantsCount: 1,
          },
        ],
      },
    };
    return dummyData;
  },
  getCycleByID: async (cycleId: string) => {
    const data: BaseResponseType<CycleByIDResponse> = {
      code: 200,
      description: "ok",
      hasError: false,
      data: {
        id: cycleId,
        name: "sample name",
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        status: "Draft",
        applicantsCount: 1,
        cycleDetails: [
          {
            locale: "En",
            name: "sample name",
            description: "sample description",
            ruleAndConditionName: "sample rules and conditions",
            ruleAndConditionTemplate: "<p style='text-align: left;'>sample data</p>",
            commitmentName: "sample commitment",
            commitmentTemplate: "<p style='text-align: left;'>sample data</p>",
          },
          {
            locale: "Ar",
            name: "اسم الدورة",
            description: "تفاصيل الدورة",
            ruleAndConditionName: "اسم القواعد والأحكام",
            ruleAndConditionTemplate: "<p style='text-align: right;'>بيانات القواعد</p>",
            commitmentName: "اسم التعهد",
            commitmentTemplate: "<p style='text-align: right;'>بيانات التعهد</p>",
          },
        ],
        financingCalculationItems: [
          {
            financingType: {
              financingTypesDetail: {
                displayName: "sample financing type",
              },
              calculationWay: {
                calculationWayDetail: {
                  displayName: "sample calculation way",
                },
              },
            },
            value: 100,
          },
        ],
        files: [
          {
            id: "1",
            name: "sample data",
            extension: "pdf",
            size: "3MB",
          },
        ],
      },
    };
    return data;
  },
  exportToExcel: async () => {
    return null;
  },
};

import { ReactElement, useRef } from "react";
import { AccordionItemProps } from "./AccordionItem";
import "./Accordion.scss";

type AccordionProps = {
  children: ReactElement<AccordionItemProps> | Array<ReactElement<AccordionItemProps>>;
  originalStyles?: boolean;
  className?: string;
  negatePadding?: boolean;
};

const Accordion: React.FC<AccordionProps> = ({
  children,
  originalStyles,
  className,
  negatePadding,
}: AccordionProps) => {
  const accordionId = useRef(Math.random().toString(36).substring(7));

  return (
    <div
      className={`accordion accordion-flush ${originalStyles ? "" : "strip-styles"} ${className ?? ""}`}
      style={negatePadding ? { marginInline: -20 } : {}}
      id={accordionId.current}
    >
      {children}
    </div>
  );
};

export default Accordion;

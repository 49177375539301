export type AccordionItemProps = {
  elementId: string | number;
  title: string;
  children: React.ReactNode | React.ReactNode[];
  initiallyOpen?: boolean;
  labelClassName?: string;
  extraHeaders?: React.ReactNode;
  mainHeaderClassName?: string;
};

const AccordionItem: React.FC<AccordionItemProps> = (props) => {
  const { elementId, title, children, initiallyOpen, extraHeaders, mainHeaderClassName } = props;

  return (
    <div className="accordion-item">
      <div className={`d-flex justify-content-between ${mainHeaderClassName}`}>
        <h2 className="accordion-header" id={`heading-${elementId}`}>
          <button
            className={`fw-bold accordion-button fs-5 ${initiallyOpen ? "" : "collapsed"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${elementId}`}
            aria-expanded="false"
            aria-controls={`collapse${elementId}`}
          >
            {title}
          </button>
        </h2>

        {extraHeaders}
      </div>

      <div
        id={`collapse${elementId}`}
        className={`accordion-collapse collapse ${initiallyOpen ? "show" : ""}`}
        aria-labelledby={`heading-${elementId}`}
        data-bs-parent="#accordionFlushExample"
      >
        <div className="accordion-body">{children}</div>
      </div>
    </div>
  );
};

export default AccordionItem;

import { useTranslation } from "react-i18next";
import FormInput from "components/blocks/FormInput";
import { useState } from "react";
import PublicationBookCard from "./PublicationBookCard";

const PublicationsGallery = () => {
  const { t, i18n } = useTranslation("WelcomePage");

  const [selectedPublicationType, setSelectedPublicationType] = useState(1);

  // TODO: get publication types from backend
  const publicationTypes = [
    {
      id: 1,
      name: "الكل",
    },
    {
      id: 2,
      name: "الرسائل العلمية",
    },
    {
      id: 3,
      name: "الكتب",
    },
    {
      id: 4,
      name: "البحوث",
    },
    {
      id: 5,
      name: "الكتيبات",
    },
    {
      id: 6,
      name: "تحقيق المخطوطات",
    },
    {
      id: 7,
      name: "الموسوعات والمعاجم",
    },
  ];

  return (
    <div>
      {/* Search box */}
      <div className="d-flex gap-3">
        <FormInput placeholder={t("Common:search")} wrapperClassName="flex-1" />
        <button className="btn btn-primary px-4">{t("Common:search")}</button>
      </div>
      {/* Type Filter */}
      <div className="d-flex gap-3 my-4 flex-wrap">
        {publicationTypes.map((type) => (
          <button
            key={type.id}
            className={`btn ${
              selectedPublicationType === type.id ? "btn-primary" : "btn-outline-secondary"
            } px-4 rounded-5`}
            onClick={() => setSelectedPublicationType(type.id)}
          >
            {type.name}
          </button>
        ))}
      </div>

      {/* Publications List */}
      <div className="d-flex gap-4 flex-wrap">
        {Array.from({ length: 10 }).map((_, index) => (
          <PublicationBookCard key={index} />
        ))}
      </div>
    </div>
  );
};

export default PublicationsGallery;

import { useTranslation } from "react-i18next";
import SPFormAttachmentsTable from "../../form/components/SPFormAttachmentsTable";

const SPVMainInfo = () => {
  const { t } = useTranslation("ScientificPublication");
  return (
    <div>
      <h6 className="fw-bold">{t("publicationDescription")}</h6>
      <p>
        لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات
        دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد أكسير سيتاشن يللأمكو لابورأس نيسي يت أليكيوب أكس
        أيا كوممودو كونسيكيوات . ديواس
      </p>

      <div className="striped d-flex flex-column position-relative ">
        <div className="d-flex flex-1 p-3">
          <div className="flex-1 fw-bold text-dark">{t("publicationField")}</div>
          <div className="flex-2">علوم الشريعة</div>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("publicationOwner")}</span>
          <span className="flex-2">جهة النشر</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("publicationOwnerType")}</span>
          <span className="flex-2">نوع جهة النشر</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("publicationLink")}</span>
          <span className="flex-2">www.link.com</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("ISSNSerialNumber")}</span>
          <span className="flex-2">1234-5678</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("numberOfPages")}</span>
          <span className="flex-2">100</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("prepareHardCopy")}</span>
          <span className="flex-2">متوفر</span>
        </div>
      </div>

      <div className="divider my-4" />

      <h6 className="fw-bold">{t("librariesHavingThisPublication")}</h6>
      <div className="d-flex flex-wrap gap-2">
        <div className="keyword-badge">المكتبة العربية</div>
        <div className="keyword-badge">اسم المكتبة</div>
        <div className="keyword-badge">اسم المكتبة</div>
        <div className="keyword-badge">اسم المكتبة</div>
        <div className="keyword-badge">اسم المكتبة</div>
      </div>

      <h6 className="fw-bold mt-4">{t("metaData")}</h6>
      <p>
        لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات
        دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد أكسير سيتاشن يللأمكو لابورأس نيسي يت أليكيوب أكس
        أيا كوممودو كونسيكيوات . ديواس
      </p>

      <h6 className="fw-bold mt-4">{t("Common:attachments")}</h6>
      <SPFormAttachmentsTable viewOnly />
    </div>
  );
};

export default SPVMainInfo;

import IconButton from "components/blocks/IconButton";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import Switch from "components/blocks/Switch";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./ScientificPublicationForm.scss";
import SPFormAttachmentsTable from "./components/SPFormAttachmentsTable";
import SPFormMainInfo from "./components/SPFormMainInfo";
import SPFormDetails from "./components/SPFormDetails";

// TODO: types
type CreateScientificPublicationRequest = any;

const ScientificPublicationForm: React.FC = () => {
  // Hooks
  const { t } = useTranslation("ScientificPublication");
  const navigate = useNavigate();
  const { isRTL, isAr } = useCookieContext();
  const { setBreadcrumbs } = useBreadcrumb();

  // STATES
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [publicationSerialNumber, setPublicationSerialNumber] = useState("");

  // EFFECTS
  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "scientificPublication",
        path: "/home/scientificPublication",
        menuItemId: "scientificPublication",
      },
      {
        localizationKey: "addScientificPublication",
        path: `/home/scientificPublication/form`,
      },
    ]);
  }, []);

  // FORM
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    reset,
    setError,
  } = useForm<CreateScientificPublicationRequest>();

  const onSubmit = (data: CreateScientificPublicationRequest) => {
    console.log(data);
  };

  return (
    <form className="position-relative" noValidate onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay visible={loading} className="position-fixed" />

      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div className="d-flex gap-2 align-items-center">
          <IconButton
            icon={isRTL ? "icon-full-arrow-right" : "icon-full-arrow-left"}
            innerIconColor="black"
            size="lg"
            className="text-dark"
            onClick={() => navigate(-1)}
          />
          <div className="d-flex gap-3 align-items-start">
            <div>
              <h4 className="text-dark mb-0">{t("addScientificPublication")}</h4>
              {publicationSerialNumber && <span>{publicationSerialNumber}</span>}
            </div>
            <Switch
              className="mt-1"
              checked={watch("status") === "Completed"}
              onChange={(checked) => {
                setValue("status", checked ? "Completed" : "Draft");
              }}
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          <button className="btn btn-primary px-3" type="submit" disabled={submitting}>
            {submitting && <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />}
            {t("Common:save")}
          </button>
          <button className="btn btn-outline-secondary px-3" type="submit" disabled={submitting}>
            {submitting && <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />}
            {t("Common:cancel")}
          </button>
        </div>
      </div>

      <div className="divider" />
      <SPFormMainInfo />
      <div className="divider" />
      <SPFormDetails />
      <div className="divider" />
      <SPFormAttachmentsTable scientificPublicationId="1" />
    </form>
  );
};

export default ScientificPublicationForm;

import FormInput from "components/blocks/FormInput";
import Modal from "components/blocks/Modal";
import { useNotification } from "hooks/useNotification";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SettingsService } from "services/settingsService";
import { Constants } from "shared/constants";

type RewardSettingsModalProps = {
  settingKey: keyof typeof Constants;
};

const RewardSettingsModal: React.FC<RewardSettingsModalProps> = ({ settingKey }) => {
  const { t } = useTranslation("Common");
  const { showNotification } = useNotification();
  const [isOpen, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [rewardAmountDbValue, setRewardAmountDbValue] = useState<number | null>();
  const [rewardAmount, setRewardAmount] = useState<number>(0);

  const fetchRewardAmount = async () => {
    // try {
    //   const response = await SettingsService.getSettings(settingKey);
    //   if (!response.hasError) {
    //     setRewardAmount(+response.data.value);
    //     setRewardAmountDbValue(+response.data.value);
    //   } else {
    //     setRewardAmount(0);
    //     setRewardAmountDbValue(null);
    //     showNotification({
    //       description: t(response.description),
    //       type: "error",
    //     });
    //   }
    // } catch (error) {
    //   setRewardAmount(0);
    //   setRewardAmountDbValue(null);
    //   showNotification({
    //     description: t("internalServerError"),
    //     type: "error",
    //   });
    // }

    // TODO: Remove this line after implementing the above code
    setRewardAmountDbValue(200);
  };

  useEffect(() => {
    fetchRewardAmount();
  }, []);

  const handleRewardAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRewardAmount(Number(e.target.value));
    setError(undefined);
  };

  const handleSave = async () => {
    setLoading(true);
    // validate the reward amount
    if (rewardAmount < 0) {
      setError(t("invalidRewardAmount"));
      return;
    }
    setError(undefined);

    try {
      const response = await SettingsService.updateSettings({
        key: settingKey,
        value: rewardAmount.toString(),
      });

      if (!response.hasError) {
        showNotification({
          description: t("success"),
          type: "success",
        });

        setRewardAmountDbValue(rewardAmount);
        fetchRewardAmount();
      } else {
        showNotification({
          description: t(response.description),
          type: "error",
        });
      }
    } catch (error) {
      showNotification({
        description: t("internalServerError"),
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setOpen(false);
    setRewardAmount(rewardAmountDbValue || 0);
    setError(undefined);
  };

  return (
    <>
      <button className="btn btn-primary px-4" onClick={() => setOpen(true)}>
        {rewardAmountDbValue === undefined ? (
          <Spinner size="sm" />
        ) : rewardAmountDbValue === null ? (
          t("errorLoadingRewardAmount-reload")
        ) : (
          t("rewardAmountRiyal", { amount: rewardAmountDbValue })
        )}
      </button>

      <Modal
        isOpen={isOpen || loading}
        onClose={onClose}
        containerClassName="bg-white"
        size="lg"
        title={t("rewardAmount")}
        withHeaderBorder
        footer={
          <div className="d-flex gap-2 flex-grow-1 justify-content-center">
            <button className="btn btn-outline-secondary px-5" onClick={onClose}>
              {t("Common:cancel")}
            </button>
            <button className="btn btn-primary px-5" onClick={handleSave} disabled={loading}>
              {t("save")} {loading && <Spinner size="sm" className="mx-2" />}
            </button>
          </div>
        }
      >
        <FormInput
          label={t("rewardAmount")}
          type="number"
          value={rewardAmount}
          onChange={handleRewardAmountChange}
          error={error}
        />
      </Modal>
    </>
  );
};

export default RewardSettingsModal;

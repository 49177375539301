import IconButton from "components/blocks/IconButton";
import ActionModal from "components/blocks/Modal/ActionModal";
import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import { useNotification } from "hooks/useNotification";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { CycleResult } from "services/cyclesService/models/cyclesTypes";

const CyclesGridView = () => {
  const { t } = useTranslation("Cycles");
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const [deleteModalProps, setDeleteModalProps] = useState({
    isOpen: false,
    name: "",
    id: "",
  });

  const { loading, gridData, totalCount, exportToExcel } = useGridData<"cyclesService", CycleResult>({
    service: "cyclesService",
    endpoint: "getAllCycles",
    exportExcelEndpoint: "exportToExcel",
    exportFileName: "cycles",
    localizationNameSpace: "Cycles",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allCycles",
        path: "/home/cycles",
        menuItemId: "allCycles",
      },
    ]);
  }, []);

  const onDeleteConfirm = async () => {
    // const res = await CyclesService.deleteCycle(deleteModalProps.id);
    // if (res.hasError) {
    // showNotification({
    //   type: "error",
    //   description: t("Common:internalServerError"),
    // });
    // } else {
    showNotification({
      type: "success",
      description: t("Common:deletedSuccessfully"),
    });
    setDeleteModalProps({ ...deleteModalProps, isOpen: false });
    // }
  };

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "id",
        displayName: t("cycleName"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        render: (row: CycleResult) => {
          return <Link to={`${row.id}`}>{row.name}</Link>;
        },
      },
      {
        field: "startDate",
        displayName: t("cycleStartDate"),
        render: (row: CycleResult) => {
          return toSystemThemeDateFormat(row.startDate);
        },
      },
      {
        field: "endDate",
        displayName: t("cycleEndDate"),
        render: (row: CycleResult) => {
          return toSystemThemeDateFormat(row.endDate);
        },
      },
      {
        field: "applicantsCount",
        displayName: t("applicantsCount"),
      },
      {
        field: "status",
        displayName: t("Common:status"),
        render: (row: CycleResult) => {
          const statusClassName = row.status === "Completed" ? "bg-success text-success " : "bg-primary text-primary";
          return <span className={`badge bg-opacity-10  rounded-4 ${statusClassName} py-2`}>{t(row.status)}</span>;
        },
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        showOnMobile: true,
        render: (row: CycleResult) => {
          return (
            <span className="d-flex gap-2">
              <IconButton fitToIconWidth icon="icon-edit" innerIconColor="primary" size="md" onClick={() => {}} />
              <IconButton
                fitToIconWidth
                icon="icon-delete"
                innerIconColor="danger"
                size="md"
                onClick={() => {
                  setDeleteModalProps({
                    isOpen: true,
                    name: row.name,
                    id: row.id,
                  });
                }}
              />
            </span>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("cycles"),
    singularName: t("cycle"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
    addPageRoute: "/home/cycles/form",
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: gridData ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
      <ActionModal
        isOpen={deleteModalProps.isOpen}
        onClose={() => {
          setDeleteModalProps({
            isOpen: false,
            name: "",
            id: "",
          });
        }}
        headerMsg={t("Common:areYouSure")}
        subMsg={t("deleteCycleModalSubMsg", {
          name: deleteModalProps.name,
        })}
        actionIcon="icon-delete"
        confirmBtnText={t("Common:yesDelete")}
        iconWrapperColor={"danger"}
        onActionConfirm={onDeleteConfirm}
      />
    </>
  );
};

export default CyclesGridView;

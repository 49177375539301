import IconButton from "components/blocks/IconButton";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import Switch from "components/blocks/Switch";
import PageWithTabs from "components/PageWithTabs";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AboutCycleTab from "./tabs/AboutCycleTab";
import { useQuery } from "hooks/useQuery";
import { CyclesService } from "services/cyclesService";
import { CycleByIDResponse } from "services/cyclesService/models/cyclesTypes";
import OrdersTab from "./tabs/OrdersTab";
import ApplicantsTab from "./tabs/ApplicantsTab";

type TapsIds = "about" | "orders" | "applicants";
const tabsIds: { [key in TapsIds]: TapsIds } = {
  about: "about",
  orders: "orders",
  applicants: "applicants",
};

const CycleView: React.FC = () => {
  const { t } = useTranslation("Cycles");
  const { setBreadcrumbs } = useBreadcrumb();
  const { cycleId } = useParams();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allCycles",
        path: "/home/cycles",
        menuItemId: "allCycles",
      },
      {
        localizationKey: "cycle-number",
        path: `/home/cycles/${cycleId}`,
        localizationParameters: { cycleNumber: "2014-22" },
      },
    ]);
  }, []);

  const { data: cycleData, loading } = useQuery<CycleByIDResponse>({
    queryFn: async () => await CyclesService.getCycleByID(cycleId ?? ""),
    triggers: [cycleId],
    options: { enabled: !!cycleId },
  });

  const subTitle = cycleData ? `${t("cycleNumber")}: ${cycleData?.id} • ${t("theCycle")}: ${cycleData.id}` : "";

  return (
    <>
      <LoadingOverlay visible={loading} position="fixed" />
      <PageWithTabs
        titleImgSrc={require("../../../../assets/image/temp-book-thumb.jpeg")}
        title={
          <div className="d-flex gap-2">
            {cycleData?.name}
            <Switch checked onChange={() => {}} />
          </div>
        }
        subTitle={subTitle}
        defaultTabId="about"
        pageHeaders={
          !cycleData
            ? []
            : [
                {
                  title: t("cycleStartDate"),
                  value: toSystemThemeDateFormat(cycleData.startDate),
                },
                {
                  title: t("cycleEndDate"),
                  value: toSystemThemeDateFormat(cycleData.endDate),
                },
                {
                  title: t("applicantsCount"),
                  value: cycleData.applicantsCount,
                },
              ]
        }
        actions={() => {
          return (
            <>
              <IconButton variant="light" icon="icon-share" />
              <IconButton variant="light" icon="icon-edit" />
              <IconButton variant="light" innerIconColor="danger" icon="icon-delete" />
            </>
          );
        }}
        tabs={[
          {
            id: tabsIds.about,
            title: t("aboutCycle"),
            content: cycleData ? <AboutCycleTab cycle={cycleData} /> : null,
          },
          {
            id: tabsIds.orders,
            title: t("orders"),
            content: cycleData ? <OrdersTab cycleId={cycleData.id} /> : null,
          },
          {
            id: tabsIds.applicants,
            title: t("applicants"),
            content: cycleData ? <ApplicantsTab cycleId={cycleData.id} /> : null,
          },
        ]}
      />
    </>
  );
};

export default CycleView;

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { TaskResult } from "services/tasks/models/tasksTypes";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import IconButton from "components/blocks/IconButton";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import ReAssignUserModal from "./components/ReAssignUserModal";
import useGridData from "../../../hooks/useGridData";

export default function TasksGridView() {
  const { t } = useTranslation("Tasks");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const { loading, gridData, totalCount, exportToExcel, fetchGridData } = useGridData<"tasksService", TaskResult>({
    service: "tasksService",
    endpoint: "getMyTasks",
    exportExcelEndpoint: "exportTasksToExcel",
    exportFileName: "tasks",
    localizationNameSpace: "Tasks",
  });

  const [reAssignModalProps, setReAssignModalProps] = useState<{
    isOpen: boolean;
    task: TaskResult | null;
    refreshTableTrigger: boolean;
  }>({
    isOpen: false,
    task: null,
    refreshTableTrigger: false,
  });

  useEffect(() => {
    fetchGridData();
  }, [reAssignModalProps.refreshTableTrigger]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "tasks",
        path: "/home/tasks",
        menuItemId: "tasks",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "ActivityName",
        displayName: t("taskName"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        render: (row: TaskResult) => {
          return (
            <button className="btn btn-link" onClick={() => navigate(row.taskUrl)}>
              {row.activityName}
            </button>
          );
        },
      },
      {
        field: "WorkflowName",
        accessor: "workflowName",
        filterable: true,
        displayName: t("workflowName"),
        sortable: true,
      },
      {
        field: "taskDate",
        displayName: t("taskDate"),
        render: (row: TaskResult) => {
          return toSystemThemeDateFormat(row.startDateTime);
        },
      },
      {
        field: "description",
        displayName: t("description"),
      },
      {
        field: "originatorUserName",
        displayName: t("sender"),
        sortable: true,
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        sortable: false,
        showOnMobile: true,
        render: (row: TaskResult) => {
          return (
            <span className="d-flex gap-2">
              <IconButton
                fitToIconWidth
                icon="icon-view"
                innerIconColor="primary"
                size="md"
                onClick={() => navigate(row.taskUrl)}
              />

              <IconButton
                fitToIconWidth
                icon={"icon-reassign"}
                innerIconColor="dark"
                size="md"
                onClick={() => {
                  setReAssignModalProps({
                    isOpen: true,
                    task: row,
                    refreshTableTrigger: reAssignModalProps.refreshTableTrigger,
                  });
                }}
              />
            </span>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("tasks"),
    singularName: t("task"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: gridData ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
      <ReAssignUserModal
        isOpen={reAssignModalProps.isOpen}
        task={reAssignModalProps.task}
        onClose={(isReassigned) =>
          setReAssignModalProps({
            isOpen: false,
            task: null,
            refreshTableTrigger: isReassigned
              ? !reAssignModalProps.refreshTableTrigger
              : reAssignModalProps.refreshTableTrigger,
          })
        }
      />
    </>
  );
}

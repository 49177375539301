import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FileUploadWithPreview from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useTranslation } from "react-i18next";

const MinutesOfMeetingTab: React.FC = () => {
  const { t } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const gridSchema: GridSchema[] = [
    { displayName: t("meetingNumber"), field: "meetingNumber" },
    {
      displayName: t("meetingDate"),
      field: "meetingDate",
      render: (row) => <div>{toSystemThemeDateFormat(row.meetingDate)}</div>,
    },
    { displayName: t("members"), field: "members" },
    { displayName: t("enterMinutesOfMeetingDetails"), field: "enterMinutesOfMeetingDetails" },
    {
      displayName: t("files"),
      field: "files",
      render: (row) => <span role="button" className="text-primary icon-view fs-20px" onClick={() => {}} />,
    },
  ];

  const data = [
    {
      meetingNumber: "1",
      meetingDate: "2024-01-01",
      members: "Member 1, Member 2",
      enterMinutesOfMeetingDetails: "Details",
      files: [],
    },
  ];

  return (
    <div>
      <FormInput
        wrapperClassName="mb-4"
        label={t("minutesOfMeetingDetails")}
        placeholder={t("minutesOfMeetingDetails")}
      />

      <FileUploadWithPreview files={[]} setFiles={() => {}} formProps={{}} />

      <div className="divider" my-2 />

      <Accordion>
        <AccordionItem initiallyOpen elementId="1" title={t("previousMinutesOfMeeting")}>
          <Grid data={data} gridSchema={gridSchema} />
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default MinutesOfMeetingTab;

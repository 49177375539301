import { RouteItemConfig } from "../models";
import DashboardView from "../../views/home/dashboard/dashboardView";
import TasksGridView from "views/home/tasks/TasksGridView";
import { Outlet } from "react-router-dom";
import CyclesGridView from "views/home/cycles/CyclesGridView";
import OrdersGridView from "views/home/orders/OrdersGridView";
import PublishersGridView from "views/home/publishersManagement/publishers/PublishersGridView";
import PublishersPaymentsGridView from "views/home/publishersManagement/publishersPayments/PublishersPaymentsGridView";
import FormTemplatesGridView from "views/home/formTemplates/FormTemplatesGridView";
import ScientificPublicationGridView from "views/home/scientificPublication/ScientificPublicationGridView";
import JudgesGridView from "views/home/judgesManagement/judges/JudgesGridView";
import JudgesRewardsGridView from "views/home/judgesManagement/judgesRewards/JudgesRewardsGridView";
import ExaminersRewardsGridView from "views/home/examinersManagement/examinersRewards/ExaminersRewardsGridView";
import ExaminersGridView from "views/home/examinersManagement/examiners/ExaminersGridView";
import CycleForm from "views/home/cycles/CycleForms";
import CycleView from "views/home/cycles/CycleView";
import OrderView from "views/home/orders/OrderView";
import UserProfile from "views/home/userProfile";
import ScientificPublicationForm from "views/home/scientificPublication/form/ScientificPublicationForm";
import PublicationView from "views/home/scientificPublication/PublicationView";

export const HomeRoutes: RouteItemConfig[] = [
  {
    path: "/home/dashboard",
    name: "dashboard",
    component: DashboardView,
    icon: "icon-dashboard",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/cycles",
    name: "allCycles",
    component: CyclesGridView,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/cycles/form",
    name: "cycleForm",
    component: CycleForm,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/cycles/:cycleId",
    name: "cycleView",
    component: CycleView,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/orders",
    name: "allOrders",
    component: OrdersGridView,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/orders/:orderId",
    name: "orderView",
    component: OrderView,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/tasks",
    name: "tasks",
    component: TasksGridView,
    icon: "icon-tasks",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/publishersManagement",
    name: "publishersManagement",
    component: Outlet,
    icon: "icon-researchers",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/publishersManagement/publishers",
        name: "publishersInfo",
        component: PublishersGridView,
        icon: "icon-researchers",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
        id: 1,
        groupName: "essentials",
      },
      {
        path: "/home/publishersManagement/publishers/view",
        name: "publisherInfo",
        component: UserProfile,
        icon: "icon-researchers",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
        id: 1,
      },
      {
        path: "/home/publishersManagement/publisherPayments",
        name: "publishersPayments",
        component: PublishersPaymentsGridView,
        icon: "icon-dashboard",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/examinersManagement",
    name: "examinersManagement",
    component: Outlet,
    icon: "icon-researchers",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/examinersManagement/examiners",
        name: "examinersInfo",
        component: ExaminersGridView,
        icon: "icon-researcher",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
      {
        path: "/home/examinersManagement/examiners/view",
        name: "researcherInfo",
        component: UserProfile,
        icon: "icon-researchers",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
        id: 1,
      },
      {
        path: "/home/examinersManagement/examinerRewards",
        name: "examinerRewards",
        component: ExaminersRewardsGridView,
        icon: "icon-dashboard",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/judgesManagement",
    name: "judgesManagement",
    component: Outlet,
    icon: "icon-researchers",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/judgesManagement/judges",
        name: "judgesInfo",
        component: JudgesGridView,
        icon: "icon-researchers",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
        id: 1,
        groupName: "essentials",
      },
      {
        path: "/home/judgesManagement/judges/view",
        name: "judgesInfo",
        component: UserProfile,
        icon: "icon-researchers",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
        id: 1,
      },
      {
        path: "/home/judgesManagement/judgeRewards",
        name: "judgesRewards",
        component: JudgesRewardsGridView,
        icon: "icon-dashboard",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/formTemplates",
    name: "formTemplates",
    component: FormTemplatesGridView,
    icon: "icon-form-builder",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/scientificPublication",
    name: "scientificPublication",
    component: ScientificPublicationGridView,
    // TODO: extract the book icon from FIGMA
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/scientificPublication/form",
    name: "scientificPublicationForm",
    component: ScientificPublicationForm,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/scientificPublication/:id",
    name: "publicationView",
    component: PublicationView,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
];

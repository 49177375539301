import { AccountService } from "./accountService";
import { FileService } from "./fileService";
import { SettingsService } from "./settingsService";
import { TasksService } from "./tasks";
import { CyclesService } from "./cyclesService";
import { OrdersService } from "./ordersService";
import { PublishersService } from "./publishersService";
import { publishersPaymentsService } from "./publishersPaymentsService";
import { ExaminersService } from "./examinersService";
import { ExaminersRewardsService } from "./examinersRewardsService";
import { JudgesService } from "./judgesService";
import { JudgesRewardsService } from "./judgesRewardsService";
import { FormTemplatesService } from "./formTemplatesService";
import { ScientificPublicationsService } from "./scientificPublicationsService";

export const BackendServices = {
  accountService: AccountService,
  settingsService: SettingsService,
  fileService: FileService,
  tasksService: TasksService,
  cyclesService: CyclesService,
  ordersService: OrdersService,
  publishersService: PublishersService,
  publishersPaymentsService: publishersPaymentsService,
  examinersService: ExaminersService,
  examinersRewardsService: ExaminersRewardsService,
  judgesService: JudgesService,
  judgesRewardsService: JudgesRewardsService,
  formTemplatesService: FormTemplatesService,
  scientificPublicationsService: ScientificPublicationsService,
};

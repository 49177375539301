import { useState } from "react";
import Logo from "../../../assets/image/login/icon.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookieContext } from "contexts";
import LanguageToggleBtn from "components/blocks/LanguageToggleBtn";

const selectedTabClass = "active fw-bold";

const NavBar = ({
  tapClassName = "text-white",
  variant = "light",
}: {
  tapClassName?: string;
  variant?: "light" | "dark";
}) => {
  const { t } = useTranslation("WelcomePage");
  const { userSession } = useCookieContext();
  const [selectedTap, setSelectedTap] = useState(3);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <img src={Logo} alt="logo" width={50} />
        <div className="d-flex align-items-center gap-3">
          <Link
            to="/"
            className={`btn btn-link fs-6 ${
              selectedTap === 1 ? selectedTabClass : ""
            } ${tapClassName} text-decoration-none`}
          >
            {t("mainPage")}
          </Link>
          <Link
            to="https://imamufunding.aaltair.net/"
            className={`btn btn-link fs-6 ${
              selectedTap === 2 ? selectedTabClass : ""
            } ${tapClassName} text-decoration-none`}
          >
            {t("fundingResearchProjects")}
          </Link>
          <Link
            to="/"
            className={`btn btn-link fs-6 ${
              selectedTap === 3 ? selectedTabClass : ""
            } ${tapClassName} text-decoration-none`}
          >
            {t("scientificPublishing")}
          </Link>
        </div>
        <div className="d-flex align-items-center gap-3">
          <Link
            to={!userSession ? "/account/login" : "/home/dashboard"}
            className="btn btn-primary p-10-32P my-4 rounded-3"
          >
            {!userSession ? t("login") : t("dashboard")}
          </Link>
          <LanguageToggleBtn className="py-10 px-3" variant={variant === "dark" ? "secondary" : "light"} />
        </div>
      </div>
    </div>
  );
};

export default NavBar;

import { Modal as BootstrapModal } from "react-bootstrap";

export type ModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  title?: string;
  headerContent?: React.ReactNode;
  footer?: React.ReactNode;
  size?: "sm" | "lg" | "xl";
  fullscreen?: boolean;
  containerClassName?: string;
  bodyMargin?: string;
  titleClassName?: string;
  withHeaderBorder?: boolean;
};

const Modal: React.FC<ModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    children,
    title,
    headerContent,
    footer,
    size,
    fullscreen,
    containerClassName,
    titleClassName,
    withHeaderBorder,
    bodyMargin = "my-4",
  } = props;

  return (
    <BootstrapModal
      fullscreen={fullscreen ? true : undefined}
      size={size}
      show={isOpen}
      onHide={onClose}
      contentClassName={`p-4 text-dark ${containerClassName}`}
    >
      <div
        className={`d-flex justify-space-between ${withHeaderBorder ? "border-bottom pb-2" : ""}`}
        style={{ maxHeight: "100%" }}
      >
        {headerContent}
        <h4 className={`modal-title flex-1 fw-bold ${titleClassName} `}>{title}</h4>
        <button type="button" className="btn-close m-0" onClick={onClose} />
      </div>
      <div className={`${bodyMargin} py-1 px-1 overflow-y-auto overflow-x-hidden flex-grow-1`}>{children}</div>
      {footer && <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>}
    </BootstrapModal>
  );
};

export default Modal;

import OrdersGridView from "views/home/orders/OrdersGridView";

type OrdersTabProps = {
  cycleId: string;
};

const OrdersTab: React.FC<OrdersTabProps> = ({ cycleId }) => {
  return <OrdersGridView cycleId={cycleId} />;
};

export default OrdersTab;
